export const state = () => ({
    open: false,
    label: null,
    action: null,
    error: null
})

export const mutations = {
    toggle(state) {
        state.open = !state.open
    },
    close(state) {
        state.open = false
        state.label = null
        state.action = null
        state.error = null
    },
    open(state, data) {
        if (data) {
            if (!data.label) { state.label = null }
            else { state.label = data.label }
            
            if (!data.action) { state.action = null }
            else { state.action = data.action }
        }
        state.open = true
    },
    setError(state, errorText) {
        state.error = errorText
    }
}

import store from "store";

export default {
  async sendContanctToPartners({ commit, rootState, state }, partnerIds) {
    const userEmail = store.get("email");

    const favPartnerIds = state.partners;
    const contactedPartnerIds =
      rootState.planner.contattaFornitori.contactSentTo;
    const contactingPartnerIds = favPartnerIds.filter(
      (partnerId) => !contactedPartnerIds.includes(partnerId)
    );

    for (const partnerId of contactingPartnerIds) {
      try {
        const url = `${this.$config.partners.publicApiUrl}/emails/send_collaboratore_email`;
        const body = {
          collaboratore_id: partnerId,
          user_email: userEmail,
          message: "Test",
        };
        const res = this.$axios.post(url, body);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }

    commit("planner/addContactedPartners", contactingPartnerIds, {
      root: true,
    });
  },
};

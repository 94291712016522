export const state = () => ({
    open: false,
    objectName: null,
    objectId: null,
    subsectionId: null,
    sectionId: null,
    label: 'Nome oggetto',
    type: 'restaurant'
})
    
export const mutations = {
    toggle(state) {
        state.open = !state.open
    },
    close(state) {
        state.open = false
    },
    open(state, object = null) {
        if (object) {
            state.label = object.label ? object.label : 'Nome oggetto';
            state.type = object.type ? object.type : 'restaurant';
            state.sectionId = object.sectionId ? object.sectionId : null;
            state.subsectionId = object.subsectionId ? object.subsectionId : null;
            state.objectName = object.name ? object.name : null;
            state.objectId = object.id ? object.id : null;
        }
        state.open = true;
    },
    setName(state, name) {
        state.objectName = name
    }
}
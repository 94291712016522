import * as  AmazonCognitoIdentity from "amazon-cognito-identity-js"
import env from '../../env';

class User {

    constructor() {
        console.log('new user', env.cognito.baseWebsite.userPoolId);
        let poolData = {
            UserPoolId: env.cognito.baseWebsite.userPoolId,
            ClientId: env.cognito.baseWebsite.clientId
        }
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        this.cognitoUser = this.userPool.getCurrentUser();
        
        if (this.cognitoUser != null) {
            this.cognitoUser.getSession(function(err, session) {
                if (err) {
                    console.dir(err);
                    return;
                }
            });
        }

    }
    
    authenticate(username, password) {
        username = username.trim();

        let authenticationData = {
            Username: username,
            Password: password
        }

        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

        let userData = {
            Username: username,
            Pool: this.userPool,
        }

        console.log('authenticate', userData);

        this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        return new Promise((resolve, reject) => {
            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: resolve,
                onFailure: reject
            })
        })
    }

    registrate(username, password, email) {
        username = username.trim();

        let attributeList = [];

        let dataEmail = {
            Name: 'email',
            Value: email,
        }

        let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
        attributeList.push(attributeEmail);

        return new Promise((resolve, reject) => {
            this.userPool.signUp(username, password, attributeList, null, function(err, result) {
                if (err) { reject(err); }
                resolve(result);
            })
        });
    }

    changePassword(oldPassword, newPassword) {
        console.log('im in');
        return new Promise((resolve, reject) => {
            this.cognitoUser.changePassword(oldPassword, newPassword, function(err, result) {
                if (err) { reject(err); }
                resolve(result);
            });
        })
    }

    signOut() {
        if (this.cognitoUser) {
            this.cognitoUser.signOut();
        }
    }

    isSessionValid() {
        if (this.cognitoUser == null) { return false; }
        return new Promise((resolve, reject) => {
            this.cognitoUser.getSession(function(err, session) {
                if (err) { reject(err) }
                resolve(result)
            });
        })
    }

    resendConfirmationCode(username) {
        username = username.trim();

        const poolData = {
            UserPoolId: env.cognito.baseWebsite.userPoolId,
            ClientId: env.cognito.baseWebsite.clientId
        };
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        const userData = {
            Username: username,
            Pool: userPool
        };
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function(err, result) {
            if (err) {
                console.dir(err);
                return;
            }
            console.dir(result);
        });
    }

    forgotPassword(username) {
        username = username.trim();

        const poolData = {
            UserPoolId: env.cognito.baseWebsite.userPoolId,
            ClientId: env.cognito.baseWebsite.clientId
        };
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        const userData = {
            Username: username,
            Pool: userPool
        };
        console.log(userData);
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function(data) {
                console.log(data);
            },
            onFailure: function(err) {
                console.dir(err);
            }
        });
    }

    
    verificationCode(username, code, password) {
        username = username.trim();
        
        let userData = {
            Username: username,
            Pool: this.userPool,
        }
        this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        return new Promise((resolve, reject) => {
            this.cognitoUser.confirmPassword(code, password, {
                onSuccess(result) {
                    resolve(result);
                },
                onFailure(err) {
                    reject(err);
                }
            });
        });
        
    }


    retriveUser(access, id) {
        const accessToken = new AmazonCognitoIdentity.CognitoAccessToken({ AccessToken: access });
        const idToken = new AmazonCognitoIdentity.CognitoIdToken({ IdToken: id });
        const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken : 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Rsg9hqfXANBerAt89rUbDdGxt63t-TcDxgwAqwaRtdX7FosoNBO9mAO1APUyEXt1p5ub99HK4avfyTXlv2u--YXVT_QopSIM6Xs3ZSK1pNruaGoV4FrSwHtGmeNxWkWoDUp9Wb082xEbCHxjN8KRdc3h-AwRplTazKczpeAiDLKav6BPYY5Tbb2SdphGWClZIOskzYnZbYGou1aItr-zwQJr9AnpAypbpNXRGCNPxBOo88yy8TgLX5ARDALpYdymzQX_HkaWmNoGyIpfl0qc4q0JqY77SDr7B-t_wMo86VomUtGYKOVr-wOnr8YV3pFFFgHYI_D2THM2LHwAgbG83A.0Ch2hWKV1Q4BjJwZ.Ib6wrIlignLzHBXiEwNg_pKeSONA1yWelhORrum2kloIXygbS1hZHH7igGXleclRKBK2EDLzGswLapf9C9MjIISFLNmiAyrS03sWDs6t94bGj1rVpVk-qP4o8ZyaEZmBVuNCaBIUh_AkntUxf1H6fs5E0oal6qR9Yf1FM1VpRS5gjIHme1UjifYsJM5sAqb29IzyfmREfIL0saS6UvHi7kZufDu6rJ8wKIexQRFh3_eoTE8nbqpvwdQC7pGMZsTWMCes2r_ZmXTHIy03NpXjkClq7X37RwoUMLA5koaUIIQRP8K90Iqd-0Dyv2kBpynCknossMZ34nWnALp3Pcww2g3PgHqtgNzEsMUEx8VMYXQ74IvLeuIw4f0w0ViwUV6Tne0h0wOIyUgSrz56rw-WydUNrVn0tOBGydXXo5bfwWk7x68HBmRlbWug86z5ZeDbatHM0W03hjYmQnhqwkupfqoL_dEZprw3U0hJyrCMVyzhksNfNBQBX_N-xsrrn0lzBvFr9PHeHQVhIuuI9i7Y1OeR_F1rHvxEE3NDcp2sndPv7cbP11kBhD6VM_7ZaoefUQ5rjnY0WL8PyepIJ7S4TPZQll0RYQSQdJGcdE75Ny81qvvZG3CQ1MeGNKsF9HjelkXLJj4-nLUrzZnsBWRxejL2U5toPxuuaEbNUeHyJvanVhrQg_jlZjF3Q2Nb_DPKfWbzi4Y_YLF-D5HHfE-lsPRBTkOFdsST-jq09DlJVh4HPfjWyPR7koPuQHaEtYVGt0jrEmhx7BYiIzI91oe16rfT-eDPIY8IfbQEwx3pRVn64mGKc5oCHFx8SDNPQ2TM3IsJYKVnYD738M6A30LXjDmtu6lz6p_NL5Dv6lWvO0sFug7nvIIqKR0rRKELZBdYAGuNqlSM7HnJLBgwqIiQzFnh18jWtfSUcxWMzMBa0WPjaTcp9sJj8UNAHhZKz3G01CJWXdeyQBSbn7j1sojksiiJ_GYUZeJZ2lqyfRtFhJ3dBe_jpz3LEny7w1U6kr5_4s2PQjj3cj17Ga5X9waLn7TxIBRz_tnY1PMMgYMZkX7xuIGot-X0RDp0xGApmpVBUa_LBgD9DT0TWzm0g8p1yjycVrLK0D82ACvsXjt567WUbZduKBbuVCJiPc5zdGBiIgdvfdvTVd_3wIgpm_UANwgEBf9XFfbfJC36i7ApXIjF2DZPjNKIQR7cvM150kUpso7s2GyQxAiydjCRyeXg2uj_5gEwuGBebfpHXr_ujPaD9ZtrYkNjNuOqF1T8MujY-4F_ylsr-g.EmUErW2oxCLhoTUcm6fHgg' });

        const session = new AmazonCognitoIdentity.CognitoUserSession({
            IdToken: idToken,
            AccessToken: accessToken,
            RefreshToken: refreshToken
        });

        console.log(session);

        const userData = {
            Username: session.idToken.payload['cognito:username'],
            Pool: this.userPool
        };

        this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        this.cognitoUser.setSignInUserSession(session);

        return {
            name: session.idToken.payload.name,
            email: session.idToken.payload.email,
            username: session.idToken.payload['cognito:username']
        }
    }
    

    getUserAttributes() {
        return new Promise((resolve, reject) => this.cognitoUser.getUserAttributes((err, result) => err ? reject(err) : resolve(result)));
    }


    updateUserAttribute(name, value) {
        let attributeList = [new AmazonCognitoIdentity.CognitoUserAttribute({ Name: name, Value: value })];
        return new Promise((resolve, reject) => this.cognitoUser.updateAttributes(attributeList, (err, result) => err ? reject(err) : resolve(result)));
    }

    confirmRegistration(username, code) {
        username = username.trim();

        const poolData = {
            UserPoolId: env.cognito.baseWebsite.userPoolId,
            ClientId: env.cognito.baseWebsite.clientId
        };
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        const userData = {
            Username: username,
            Pool: userPool
        };

        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (error, result) => {
                if (error) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            });
        });
    }

}

export default new User;
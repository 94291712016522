//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from './components/TheHeader.vue'
import MobileHeader from './components/MobileHeader.vue'
import DarkFooter from './components/DarkFooter.vue'
import AuthenticationPopup from './components/AuthenticationPopup.vue'

export default {
    name: 'DefaultLayout',
    components: {
        TheHeader,
        MobileHeader,
        DarkFooter,
        AuthenticationPopup
    }
}

export default {
    isValid(state) {
        // Main panel
        if (
            !state.nome || state.nome == '' ||
            !state.partita_iva || state.partita_iva == '' ||
            !state.logo || state.logo == '' ||
            !state.copertina || state.copertina == '')
        {
            return false;
        }

        // Contacts
        if (
            !state.posizione.citta || state.posizione.citta == '' ||
            !state.posizione.provincia || state.posizione.provincia == '' ||
            !state.posizione.indirizzo || state.posizione.indirizzo == '' ||
            !state.posizione.codice_postale || state.posizione.codice_postale == '' ||
            !state.contatti.email || state.contatti.email == '' ||
            !state.contatti.telefono || state.contatti.telefono == ''
        ) {
            return false;
        }

        // Served areas
        if (
            !state.zone_servite || 
            (
                !state.zone_servite.italia &&
                !state.zone_servite.estero &&
                !state.zone_servite.regioni_selezionate.length &&
                !state.zone_servite.province_selezionate.length
            )
        ) 
        {
            return false;
        }

        // Gallery and description
        /*
        if (!state.descrizione || !state.gallery.length)
        {
            return false;
        }
        */

        return true;
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storage from "~/assets/js/Storage";
import { mapMutations } from "vuex";
import introJs from "intro.js/intro.js";
import "intro.js/introjs.css";
import User from "~/assets/js/User";

export default {
  name: "MobileAnalisiMenu",
  mounted() {
    if (this.$localStore.get("showTutorial")) {
      setTimeout(() => {
        this.startTutorial();
        User.updateUserAttribute("custom:show_tutorial", "1").then(() =>
          this.$localStore.set("showTutorial", false)
        );
      }, 3000);
    }
  },
  methods: {
    ...mapMutations({
      setActiveSection: "pianificaSidebar/setActiveSection",
      initAnalysis: "analysis/init",
      initRestaurant: "restaurant/init",
      initMenu: "menu/init",
      setMenuEdited: "menu/setEdited",
      setRestaurantEdited: "restaurant/setEdited",
    }),
    saveMenu() {
      this.$store.dispatch("menu/save");
    },
    saveRestaurant() {
      this.$store.dispatch("restaurant/save");
    },
    async requestAnalysis() {
      if (!storage.getMenuList().length) {
        this.$router.push({
          path: "/pianifica/menu/nuovo",
          query: { analisiMenu: true },
        });
        return;
      }

      if (this.$store.state.menu.edited) {
        let confirmed = await this.$store.dispatch("exitPopup/getConfirmation");

        // stay on current page
        if (confirmed === null) {
          return;
        }

        // save and change page
        if (confirmed) {
          await this.$store.dispatch("menu/save");
        } else {
          // delete changes
          let menuData = storage.getMenu(this.$store.state.menu.data.menu_id);
          this.initMenu(menuData);
          this.setMenuEdited(false);
        }
      }

      this.$store.dispatch("analysis/requestPartial");
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storage from "~/assets/js/Storage";

export default {
  name: "RistoratoriAnalisiMenuLeftSidebar",
  data() {
    return {
      activeMenuId: null,
      menus: [],
    };
  },
  created() {
    this.activeMenuId = this.$localStore.get("activeMenuId");
    this.syncMenus();
  },
  methods: {
    syncMenus() {
      this.menus.splice(0);
      const menus = storage.getMenuList();
      for (const menu of menus) {
        this.menus.push(menu);
      }
    },
    saveMenu() {
      this.$store.dispatch("menu/save");
    },
    async requestMenuAnalysis() {
      if (!storage.getMenuList().length) {
        this.$router.push({
          path: "/pianifica/menu/nuovo", //todo
          query: { analisiMenu: true },
        });
        return;
      }
      this.$store.dispatch("analysis/requestPartial");
    },
    async loadMenuStore(event) {
      //TODO
      /*
      if (this.$store.state.menu.edited) {
        // show exit popup
        let confirmed = await this.$store.dispatch("exitPopup/getConfirmation");

        // calse close
        if (confirmed === null) {
          // reset select value and return
          event.target.value = this.$localStore.get("activeMenuId");
          return;
        }
        // case save
        if (confirmed) {
          // save restaurant
          await this.$store.dispatch("menu/save");
        } else {
          // delete changes
          let menuData = storage.getMenu(this.$store.state.menu.data.menu_id);
          this.initMenu(menuData);
          this.setMenuEdited(false);
        }
      }
      */

      // load menu and change page
      let menuId = event.target.value;

      console.log("dpmn", menuId);

      // load menu
      let menuData = storage.getMenu(menuId);
      this.$store.commit("menu/init", menuData);

      // set active menu id
      this.$localStore.set("activeMenuId", menuId);

      // redirect to menu page
      this.$router.push({ path: "/ristoratori/analisi-menu/" });
    },
  },
  watch: {
    $route() {
      this.activeMenuId = this.$localStore.get("activeMenuId");
      this.syncMenus();
    },
  },
};

//
//
//
//
//
//

export default {
    name: 'BlankLayout'
}

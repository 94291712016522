export default () => ({
    pages: {},
    categories: [],
    sidebar: {
        isOpen: false
    },
    pagination: {
        pageIndex: 0,
        totalPages: null
    },
    loading: false
});

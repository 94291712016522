//
//
//
//
//
//
//
//
//

import TheHeader from '../components/TheHeader.vue'
import AuthenticationHeader from '../components/partners/AuthenticationHeader.vue'
import MobileAuthenticationHeader from '../components/partners/MobileAuthenticationHeader.vue'
import SupportWidget from '../../components/support/SupportWidget.vue'

export default {
    name: 'PartnersAuthentication',
    components: {
        TheHeader,
        AuthenticationHeader,
        MobileAuthenticationHeader,
        SupportWidget
    },
    data () {
        return {
            isPartner: this.$localStore.get('pAuthenticated')
        }
    }
}

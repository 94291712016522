//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
import TheButton from "../../components/general/TheButton.vue";
import storage from "~/assets/js/Storage";
import "intro.js/introjs.css";
import SideBanner from "../../components/ad-banners/SideBanner.vue";

export default {
  name: "AnalisiMenuSidebar",
  components: {
    TheButton,
    SideBanner,
  },
  computed: {
    markerTopPercentage() {
      let activePage = this.$store.state.pianificaSidebar.activePage;
      let activeSection = this.$store.state.pianificaSidebar.activeSection;
      let position =
        this.$store.state.pianificaSidebar[activePage].order.indexOf(
          activeSection
        );
      let sectionCount =
        Object.keys(this.$store.state.pianificaSidebar[activePage].sections)
          .length - 1;
      let percentage = (position * 100) / sectionCount;
      return percentage;
    },
  },
  methods: {
    ...mapMutations({
      setActivePage: "pianificaSidebar/setActivePage",
      initAnalysis: "analysis/init",
      initMenu: "menu/init",
      setMenuEdited: "menu/setEdited",
    }),
    saveMenu() {
      this.$store.dispatch("menu/save");
    },
    async requestPartialAnalysis() {
      if (!storage.getMenuList().length) {
        this.$router.push({
          path: "/pianifica/menu/nuovo",
          query: { analisiMenu: true },
        });
        return;
      }

      if (this.$store.state.menu.edited) {
        let confirmed = await this.$store.dispatch("exitPopup/getConfirmation");

        // stay on current page
        if (confirmed === null) {
          return;
        }

        // save and change page
        if (confirmed) {
          await this.$store.dispatch("menu/save");
        } else {
          // delete changes
          let menuData = storage.getMenu(this.$store.state.menu.data.menu_id);
          this.initMenu(menuData);
          this.setMenuEdited(false);
        }
      }

      /*
      let analysis = storage.getPartialAnalysis(
        this.$localStore.get("activeMenuId")
      );

      if (analysis) {
        this.initAnalysis(analysis);
        this.$router.push("/analisi-menu/analisi");
      } else {
        */
      // request analisys
      this.$store.dispatch("analysis/requestPartial");
      //}
    },
  },
};

export const state = () => ({
    analysis: {
        showFullBanner: false,
        showSideBanner: false
    }
});
    
export const mutations = {
    setAnalysisShowFullBanner(state, bool) {
        state.analysis.showFullBanner = bool;
    },
    setAnalysisshowSideBanner(state, bool) {
        state.analysis.showSideBanner = bool;
    }
}
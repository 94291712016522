//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["error"],
  layout: "ristoratori/withoutSidebar",
};

//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NewsletterForm'
}

export default function normalizeMenu(obj) {
    let normalizedObj = {}

    normalizedObj.avg_order = {}

    for (const property in obj) {
        if (typeof obj[property] === 'undefined') { continue }

        // if property is not an array or object
        if (typeof obj[property] !== 'object') {
            normalizedObj[property] = obj[property]
            continue
        }

        if (property === 'sections') {
            normalizedObj.sections = []

            for (const section in obj.sections) {
                let propertyName = 'section_'+section

                normalizedObj.avg_order[propertyName] = obj.avg_order[section]

                normalizedObj.sections.push(propertyName)

                normalizedObj[propertyName] = {}

                normalizedObj[propertyName].disabled = obj.sections[section].disabled
                normalizedObj[propertyName].name = obj.sections[section].name
                normalizedObj[propertyName].dishes = []

                for (const dish in obj.sections[section].dishes) {
                    let dishName = 'section_'+section+'_dish_'+dish
                    
                    normalizedObj[propertyName].dishes.push(dishName)
                    
                    normalizedObj[dishName] = {}
                    
                    normalizedObj[dishName].cost = obj.sections[section].dishes[dish].cost
                    normalizedObj[dishName].disabled = obj.sections[section].dishes[dish].disabled
                    normalizedObj[dishName].favorite = obj.sections[section].dishes[dish].favorite
                    normalizedObj[dishName].name = obj.sections[section].dishes[dish].name
                    normalizedObj[dishName].price = obj.sections[section].dishes[dish].price
                }
            }
        }
    }

    return normalizedObj
}
export const state = () => ({
    open: false,
    tab: 'signIn'
});
    
export const mutations = {
    toggle(state) {
        state.open = !state.open;
    },
    switchTab(state) {
        state.tab = state.tab === 'signIn' ? 'signUp' : 'signIn';
    },
    openSignIn(state) {
        state.open = true;
        state.tab = 'signIn';
    },
    openSignUp(state) {
        state.open = true;
        state.tab = 'signUp';
    },
    openForgotPassword(state) {
        state.open = true;
        state.tab = 'forgotPassword';
    }
}

import Vue from 'vue'

export default{
    init(state, data) {
        state.data = data
    },
    setEdited(state, bool) {
        state.edited = bool
    },
    updateObjectQty(state, data) {
        state.data[data.objectId].quantity = parseInt(data.quantity)
        state.edited = true
    },
    updateObjectPrice(state, data) {
        state.data[data.objectId].price = parseFloat(data.price)
        state.edited = true
    },
    updateObjectName(state, data) {
        state.data[data.objectId].name = data.name
        state.edited = true
    },
    deleteObject(state, data) {
        let index = null;
        for (const i in state.data[data.subsectionId].objects) {
            if (state.data[data.subsectionId].objects[i] == data.objectId) {
                index = i;
                break;
            }
        }
        
        Vue.delete(state.data[data.subsectionId].objects, index);
        Vue.delete(state.data, data.objectId);
        state.edited = true;
    },
    createObject(state, object) {
        let str = state.data[object.subsectionId].objects.at(-1);
        
        let index = 0;
        if (str) {
            index = parseInt(str.split('_').at(-1)) + 1;
        }

        let subsectionId = object.subsectionId;

        let key = subsectionId.replace('subsection', '');

        key = 'object' + key + '_' + index;

        state.data[object.subsectionId].objects.push(key);

        Vue.set(state.data, key, {
            name: object.name,
            price: 0,
            quantity: 1
        });

        state.edited = true;
    },
    setGiorniApertura(state, days) {
        days = parseInt(days)
        days = isNaN(days) ? 0 : days
        days = days < 0 ? 0 : days
        days = days > 360 ? 360 : days
        state.data.giorni_apertura = days
        state.edited = true
    },
    setDimensione(state, size) {
        size = parseInt(size)
        size = isNaN(size) ? 50 : size
        size = size < 50 ? 50 : size
        size = size > 1000 ? 1000 : size
        state.data.dimensione = size
        state.edited = true
    },
    setPostiASedere(state, seats) {
        state.data.posti_a_sedere = seats;
        state.edited = true;
    },
    setAffitto(state, price) {
        price = parseInt(price)
        price = isNaN(price) ? 0 : price
        price = price < 0 ? 0 : price
        state.data.affitto = price
        state.edited = true
    },
    setOreServizio(state, hours) {
        hours = parseInt(hours)
        hours = hours < 4 ? 4 : hours
        hours = hours > 15 ? 15 : hours
        state.data.ore_servizio = hours
        state.edited = true
    },
    setOreRotazioneTavolo(state, hours) {
        hours = parseFloat(hours)
        if (![0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3].includes(hours)) {
            hours = 0.5
        }
        state.data.ore_rotazione_tavolo = hours
        state.edited = true
    },
    setName(state, name) {
        state.data.name = name
        state.edited = true
    },
    addTable(state) {
        let lastTableId = state.data.tavoli.at(-1)
        
        let index = parseInt(lastTableId.split('_').at(-1)) + 1

        let key = 'tavoli_' + index

        state.data.tavoli.push(key)

        Vue.set(state.data, key, {
            dimensione: 1,
            quantita: 1
        })

        state.edited = true
    },
    deleteTable(state, id) {
        if (state.data.tavoli.length <= 1) { return }
        state.data.tavoli.splice(state.data.tavoli.indexOf(id), 1);
        Vue.delete(state.data, id)
        state.edited = true
    },
    setTableQuantity(state, data) {
        state.data[data.id].quantita = data.quantity
        state.edited = true
    },
    setTableSize(state, data) {
        state.data[data.id].dimensione = data.size
        state.edited = true
    },
    setEdited(state, bool) {
        state.edited = bool
    }
}
export default {
    "cibo_e_bevande_analcoliche": {
        "id": "cibo_e_bevande_analcoliche",
        "name": "Cibo e ingredienti",
        "companyExclusive": true
    },
    "vino_e_bevande_alcoliche": {
        "id": "vino_e_bevande_alcoliche",
        "name": "Bevande",
        "companyExclusive": true
    },
    "packaging": {
        "id": "packaging",
        "name": "Packaging",
        "companyExclusive": true
    },
    "macchinari_e_impianti": {
        "id": "macchinari_e_impianti",
        "name": "Macchinari e Impianti",
        "companyExclusive": true
    },
    "arredamento": {
        "id": "arredamento",
        "name": "Arredamento",
        "companyExclusive": true
    },
    "attrezzature": {
        "id": "attrezzature",
        "name": "Attrezzature",
        "companyExclusive": true
    },
    "architetti_e_interior_designer": {
        "id": "architetti_e_interior_designer",
        "name": "Architetti e Interior Designer"
    },
    "web_designers_e_grafica": {
        "id": "web_designers_e_grafica",
        "name": "Web Designers e Grafica"
    },
    "servizi_software": {
        "id": "servizi_software",
        "name": "Servizi Software"
    },
    "servizi_manutenzione_e_pulizia": {
        "id": "servizi_manutenzione_e_pulizia",
        "name": "Servizi Manutenzione e Pulizia"
    },
    "fornitura_servizi_energetici_idrici_e_di_smaltimento": {
        "id": "fornitura_servizi_energetici_idrici_e_di_smaltimento",
        "name": "Fornitura servizi energetici, idrici e di smaltimento",
        "companyExclusive": true
    },
    "formazione": {
        "id": "formazione",
        "name": "Formazione"
    },
    "commercialista": {
        "id": "commercialista",
        "name": "Commercialista"
    },
    "assistenza_legale": {
        "id": "assistenza_legale",
        "name": "Assistenza Legale"
    },
    "consulenza_del_lavoro": {
        "id": "consulenza_del_lavoro",
        "name": "Consulenza del Lavoro"
    },
    "consulenza_aziendale": {
        "id": "consulenza_aziendale",
        "name": "Consulenza aziendale"
    },
    "consulenza_di_digital_marketing": {
        "id": "consulenza_di_digital_marketing",
        "name": "Consulenza di Digital Marketing"
    },
    "servizi_assicurativi": {
        "id": "servizi_assicurativi",
        "name": "Servizi Assicurativi"
    },
    "altro": {
        "id": "altro",
        "name": "Altro"
    }
}

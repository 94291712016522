import store from "~/assets/js/Storage";

export default {
  init(state, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        state[key] = data[key];
      }
    }
  },
  setSubstepField(state, { substep, field, value }) {
    state[substep].fields[field] = value;
  },
  updateSaveCounts(state, { substep, value }) {
    if (state[substep].saveCounts < value) {
      state[substep].saveCounts = value;
    }
  },
  addContactedPartners(state, partnerIds) {
    for (const partnerId of partnerIds) {
      if (!state.contattaFornitori.contactSentTo.includes(partnerId)) {
        state.contattaFornitori.contactSentTo.push(partnerIds);
      }
    }
  },
};

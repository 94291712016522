export const state = () => ({
    isVisible: false,
    timestamp: null,
    status: null,
    text: null
});
    
export const mutations = {
    show(state, timestamp) {
        state.isVisible = true;
        state.timestamp = timestamp;
    },
    hide(state) {
        state.isVisible = false;
    },
    setStatus(state, status) {
        state.status = status;
    },
    setText(state, text) {
        state.text = text;
    }
}

export const actions = {
    notify({ state, commit }, data) {
        commit('setStatus', data.status);
        commit('setText', data.text);
        let timestamp = Date.now();
        commit('show', timestamp);
        setTimeout(() => {
            if (state.timestamp == timestamp) {
                commit('hide');
            }
        }, 4000);
    }
}
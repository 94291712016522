//
//
//
//
//
//
//
//
//
//
//
//

import TopHeader from "./components/TopHeader.vue";
import MobileTopHeader from "./components/MobileTopHeader.vue";

export default {
  name: "RistoratoriWithoutSidebarLayout",
  middleware: 'authenticated',
  components: {
    TopHeader,
    MobileTopHeader,
  },
  data() {
    return {
      mobileIsOpen: false
    }
  },
  methods: {
    setMobileOpen(value) {
      this.mobileIsOpen = value;
    }
  }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import TheMenu from '../components/partials/TheMenu.vue';
	import TheButton from '../../components/general/TheButton.vue';
	import { mapMutations } from 'vuex';
	import User from '~/assets/js/User';
    export default {
        name: 'TheHeader',
        data() {
            return {
                accountMenuPopupOpen: false,
                scrolled: false
            }
        },
        props: {
            homepage: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isPartnersHomepage() {
                return ['partners','partners-index-alt'].includes(this.$route.name); 
            },
            isHomepage() {
                return this.homepage && !['partners','partners-index-alt'].includes(this.$route.name);
            },
			autenticated() {
				return this.$localStore.get('authenticated');
			},
            username() {
                return this.$localStore.get('name') ? this.$localStore.get('name') : this.$localStore.get('username');
            },
            welcomeMessage() {
                let date = new Date();
                let currentHour = date.getHours();
                return currentHour > 12 ? 'Buonasera' : 'Buongiorno';
            }
		},
        components: {
            TheMenu,
            TheButton
        },
		methods: {
            signOut() {
                this.$localStore.clearAll();
                this.$cookies.remove('authenticated');
                User.signOut();
                window.location.href = '/';
            },
            handleScroll() {
                this.scrolled = window.scrollY > 200; 
            }
		},
        beforeMount () {
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy () {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Regions from '../../../../utilities/regions'

export default {
    name: 'ServedAreasEditPanel',
    data() {
        return {
            allRegions: false,
            openAllRegions: true,
            abroad: false,
            regions: Regions,
            openRegions: [],
            selectedRegions: [],
            selectedProvinces: []
        }
    },
    created() {
        this.$parent.save = this.save;

        try { this.allRegions = this.$store.state.partner.zone_servite.italia; } catch(e) { this.allRegions = false; }
        try { this.abroad = this.$store.state.partner.zone_servite.estero; } catch(e) { this.abroad = false; }
        
        let regions;
        try { regions = this.$store.state.partner.zone_servite.regioni_selezionate } catch(e) { regions = [] }
        regions.forEach((region) => {
            this.selectRegion(region);
        });

        let provinces;
        try { provinces = this.$store.state.partner.zone_servite.province_selezionate } catch(e) { provinces = [] }
        provinces.forEach((province) => {
            this.selectProvince(province);
        });
    },
    methods: {
        // Region open/close
        closeRegion(regionName) {
            if (this.isRegionOpen(regionName)) {
                const index = this.openRegions.indexOf(regionName);
                this.openRegions.splice(index, 1);
            }
        },
        toggleRegion(regionName) {
            if (this.isRegionOpen(regionName)) {
                this.closeRegion(regionName);
            }
            else {
                this.openRegions.push(regionName);
            }
        },
        isRegionOpen(regionName) {
            return this.openRegions.includes(regionName);
        },

        // Region selection
        isRegionSelected(regionName) {
            return this.selectedRegions.includes(regionName);
        },
        selectRegion(regionName) {
            if (!this.isRegionSelected(regionName)) {
                this.selectedRegions.push(regionName);
                let region;
                for (const k of this.regions) {
                    if (k.name === regionName) {
                        region = k;
                        break;
                    }
                }
                if (region) {
                    region.provinces.forEach((provinceName) => {
                        this.selectProvince(provinceName);
                    });
                }
            }
        },
        deselectRegion(regionName, bubble = true) {
            if (this.isRegionSelected(regionName)) {
                const index = this.selectedRegions.indexOf(regionName);
                this.selectedRegions.splice(index, 1);

                this.disableAllRegions(false);
            }
            if (bubble) { //bouble on province
                let region;
                for (const k of this.regions) {
                    if (k.name === regionName) {
                        region = k;
                        break;
                    }
                }
                region.provinces.forEach((provinceName) => {
                    this.deselectProvince(provinceName);
                });
            }
        },
        toggleSelectRegion(regionName) {
            if (this.isRegionSelected(regionName)) {
                this.deselectRegion(regionName);
            }
            else {
                this.selectRegion(regionName);
            }
        },

        // Provincie selection
        isProvinceSelected(provinceName) {
            return this.selectedProvinces.includes(provinceName);
        },
        selectProvince(provinceName) {
            if (!this.isProvinceSelected(provinceName)) {
                this.selectedProvinces.push(provinceName);
                // check if all provices of region are selected
                for (const region of this.regions) {
                    let fullRegion = true;
                    for (const provice of region.provinces) {
                        if (!this.selectedProvinces.includes(provice)) {
                            fullRegion = false;
                        }
                    }
                    if (fullRegion) {
                        this.selectRegion(region.name);
                    }
                }
            }
        },
        deselectProvince(provinceName) {
            if (this.isProvinceSelected(provinceName)) {
                const index = this.selectedProvinces.indexOf(provinceName);
                this.selectedProvinces.splice(index, 1);

                let region;
                for (const k of this.regions) {
                    if (k.provinces.includes(provinceName)) {
                        region = k.name;
                    }
                }
                this.deselectRegion(region, false);
            }
        },
        toggleSelectProvince(provinceName) {
            if (this.isProvinceSelected(provinceName)) {
                this.deselectProvince(provinceName);
            }
            else {
                this.selectProvince(provinceName);
            }
        },

        // All regions
        toggleAllRegion() {
            this.allRegions = !this.allRegions;
            if (this.allRegions) {
                this.enableAllRegions();
            }
            else {
                this.disableAllRegions();
            }
        },
        enableAllRegions() {
            this.allRegions = true;
            for (const k of this.regions) {
                this.selectRegion(k.name);
            }
        },
        disableAllRegions(bubble = true) {
            this.allRegions = false;
            if (bubble) {
                for (const k of this.regions) {
                    this.deselectRegion(k.name);
                }
            }
        },

        async save() {
            // if (!this.validateInput()) { return; }
            
            // Save on vuex
            this.$store.commit('partner/set', { 
                zone_servite: {
                    estero: this.abroad,
                    italia: this.allRegions,
                    regioni_selezionate: this.selectedRegions,
                    province_selezionate: this.selectedProvinces
                }
            });
            // Dispatch vuex save
            await this.$store.dispatch('partner/saveServedAreas');
            this.$store.commit('panelEditPopup/close');
        },
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TheButton',
    props: {
        to: String,
        href: String,
        purple: Boolean,
        orange: Boolean,
        green: Boolean,
        red: Boolean,
        outline: Boolean,
        white: Boolean
    },
    computed: {
        bgColor: function() {
            if (this.outline) { return 'bg-transparent' }
            if (this.orange) { return 'bg-orange' }
            if (this.green) { return 'bg-green' }
            if (this.red) { return 'bg-red' }
            if (this.white) { return 'bg-white' }
            return 'bg-purple'
        },
        borderColor: function() {
            if (this.orange) { return 'border-orange' }
            if (this.green) { return 'border-green' }
            if (this.red) { return 'border-red' }
            if (this.white) { return 'border-white' }
            return 'border-purple'
        },
        hoverBgColor: function() {
            if (this.outline) { return }
            if (this.orange) { return 'hover-bg-orange' }
            if (this.green) { return 'hover-bg-green' }
            if (this.red) { return 'hover-bg-red' }
            if (this.white) { return 'hover-bg-purple' }
            return 'hover-bg-purple'
        },
        hoverBorderColor: function() {
            if (this.orange) { return 'hover-border-orange' }
            if (this.green) { return 'hover-border-green' }
            if (this.red) { return 'hover-border-red' }
            if (this.white) { return 'hover-border-purple' }
            return 'hover-border-purple'
        },
        textColor: function() {
            if (this.outline) { 
                if (this.orange) { return 'color-orange hover-color-dark-orange' }
                if (this.green) { return 'color-green hover-color-dark-green' }
                if (this.red) { return 'color-red hover-color-dark-red' }
                return 'color-purple hover-color-dark-purple'
            }
            if (this.orange) { return 'color-grey' }
            if (this.white) { return 'color-purple hover:text-white' }
            return 'text-white hover:text-white'
        }
    }
}

import numeral from 'numeral'

numeral.register('locale', 'it', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'st' : number === 2 ? 'nd' : number === 3 ? 'rd' : 'th';
    },
    currency: {
        symbol: '€'
    }
})

numeral.locale('it')

export default (context, inject) => {
    inject('numeral', numeral)
}
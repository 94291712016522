export default () => ({
  analisiMercato: {
    saveCounts: 1,
    fields: {
      mission: null,
      valori: null,
      tipologiaCucina: null,
      profiloDemografico: null,
      reddito: null,
      stileVita: null,
      interessi: null,
      concorrenti: null,
      puntiForza: null,
      puntiDeboli: null,
      differenza: null,
      atmosfera: null,
      servizio: null,
      presentazionePiatti: null,
      politicaPrezzi: null,
      clienteTipico: null,
    },
  },
  locale: {
    saveCounts: 1,
    restaurant_id: 333,
  },
  menu: {
    saveCounts: 1,
    menu_id: 333,
  },
  analisi: {
    saveCounts: 1,
    restaurant_id: 333,
    menu_id: 333,
  },
  adempimentiBurocratici: {
    saveCounts: 1,
    fields: {
      tipoSocieta: false,
      iscrizioneRegistro: false,
      inpsInail: false,
      scia: false,
      comunicazioneUnica: false,
      corsoSab: false,
      haccp: false,
      autorizzazioneSanitaria: false,
      permessoDogane: false,
      certificazioneAntincendio: false,
      normativeUrbanistiche: false,
      impiantiNorma: false,
      permessoInsegna: false,
      permessoOccupazione: false,
      siae: false,
      conai: false,
      pos: false,
    },
  },
  contattaFornitori: {
    contactSentTo: [],
  },
  apertura: {
    fields: {
      mailSent: false,
    },
  },
});

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {v4 as uuidv4} from 'uuid'

export default {
    name: 'GalleryEditPanel',
    data() {
        return {
            description: null,
            images: [],
            loading: false,
            showErrors: false,
            uploadError: false
        }
    },
    created() {
        this.$parent.save = this.save;
        
        this.description = this.$store.state.partner.descrizione;
        this.$store.state.partner.gallery.forEach(image => this.images.push(image));
    },
    methods: {
        async save() {
            if (!this.validateInput()) { return; }
            // Save on vuex
            this.$store.commit('partner/emptyGallery');
            this.images.forEach(image => {
                this.$store.commit('partner/pushImage', image);
            });
            this.$store.commit('partner/set', { descrizione: this.description });
            // Dispatch vuex save
            await this.$store.dispatch('partner/saveGallery');
            this.$store.commit('panelEditPopup/close');
        },

        async getFileObject(e){
            this.loading = true;
            this.uploadError = false;

            try {
                await Promise.all([...e.target.files].map(async (file) => {
                    let formData = new FormData();
                    formData.append('file', file);
                    const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/${uuidv4()}`, formData, { 
                        headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
                    });

                    if (this.images.length < 10) {
                        this.images.push(data.file_name);
                    }
                }));
                this.$refs.fileInput.value = "";
            }
            catch (error) {
                console.log(error);
                this.uploadError = true;
            }
            
            this.loading = false;
        },

        validateInput() {
            if (!this.description || this.description == '' || !this.images.length) {
                this.showErrors = true;
                return false;
            }
            return true;
        },
        
        remove(key) {
            this.images.splice(key, 1);
        }
    }
}

//
//
//
//
//
//
//
//
//
//
//
//

import CompletionDot from "../commons/CompletionDot.vue";

export default {
  name: "PlannerSidebarSubstep",
  components: { CompletionDot },
  props: {
    to: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    completed() {
      return this.percentage >= 100;
    },
  },
};

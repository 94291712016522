//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Categories from '../../../../utilities/partners/categories'

export default {
    name: "CategoriesEditPanel",
    data() {
        return {
            selfEmployed: null,
            Categories,
            activeCategories: [],
            showErrors: false
        }
    },

    created() {
        this.selfEmployed = this.$localStore.get('pType') === 'LavoratoreAutonomo';

        this.$parent.save = this.save;

        this.$store.state.partner.categorie.forEach(categoryId => {
            this.activeCategories.push(categoryId);
        });
    },

    computed: {
        categories() {
            let arr = [];
            for (const key in Categories) {
                if (key !== 'altro') {
                    arr.push(Categories[key]);
                }
            }
            return arr;
        }
    },

    methods: {

        isActive(categoryId) {
            return this.activeCategories.includes(categoryId);
        },

        add(categoryId) {
            if (this.activeCategories.length >= 5) {
                return;
            }
            if (!this.isActive(categoryId)) {
                this.activeCategories.push(categoryId);
            }
        },

        remove(categoryId) {
            for (const index in this.activeCategories) {
                if (this.activeCategories[index] === categoryId) {
                    this.activeCategories.splice(index, 1);
                }
            }
        },

        async save() {
            if (!this.validateInput()) { return; }
            // Save on vuex
            this.$store.commit('partner/set', { categorie: this.activeCategories });
            // Dispatch vuex save
            await this.$store.dispatch('partner/saveCategories');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            if (!this.activeCategories.length) {
                this.showErrors = true;
                return false;
            }
            return true;
        }

    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PlannerSidebarStep from "~/components/ristoratori/planner/sidebar/Step.vue";
import PlannerSidebarSubstep from "~/components/ristoratori/planner/sidebar/Substep.vue";

export default {
  name: "RistoratoriPlannerLeftSidebar",
  components: {
    PlannerSidebarStep,
    PlannerSidebarSubstep,
  },
  computed: {
    analisiMercatoPercentage() {
      return this.$store.getters["planner/getAnalisiMercatoPercentage"];
    },
    localePercentage() {
      return this.$store.getters["planner/getLocalePercentage"];
    },
    menuPercentage() {
      return this.$store.getters["planner/getMenuPercentage"];
    },
    analisiPercentage() {
      return this.$store.getters["planner/getAnalisiPercentage"];
    },
    stepTwoPercentage() {
      const a = parseInt(this.localePercentage * 0.3);
      const b = parseInt(this.menuPercentage * 0.3);
      const c = parseInt(this.analisiPercentage * 0.4);
      return a + b + c;
    },
    adempimentiBurocraticiPercentage() {
      return this.$store.getters["planner/getAdempimentiBurocraticiPercentage"];
    },
    fornitoriPreferitiPercentage() {
      return this.$store.getters["favourites/getFornitoriPreferitiPercentage"];
    },
    contattaFornitoriPercentage() {
      return this.$store.state.planner.contattaFornitori.contactSentTo.length
        ? 100
        : 0;
    },
    stepThreePercentage() {
      return this.adempimentiBurocraticiPercentage;
      //const a = parseInt(this.adempimentiBurocraticiPercentage * 0.3);
      //const b = parseInt(this.fornitoriPreferitiPercentage * 0.3);
      //const c = parseInt(this.contattaFornitoriPercentage * 0.3);
      //return a + b + c;
    },
    totalPercentage() {
      const a = parseInt(this.analisiMercatoPercentage * 0.2);
      const b = parseInt(this.stepTwoPercentage * 0.4);
      const c = parseInt(this.stepThreePercentage * 0.4);
      return a + b + c;
    },
    stepFourPercentage() {
      return this.$store.state.planner.apertura.mailSent ? 100 : 0;
    },
  },
};

export const state = () => ({
    restaurants: [],
    menus: []
})
    
export const mutations = {
    setRestaurants(state, restaurants) {
        state.restaurants = restaurants
    },
    setMenus(state, menus) {
        state.menus = menus
    }
}
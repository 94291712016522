export default () => ({
    is_beta: false,
    collaboratore_id: null,
    has_paid: false,
    slug: null,
    nome: null,
    molteplici_novita: [],
    contatti: {
        email: null,
        telefono: null,
        social: {
            facebook: null,
            instagram: null,
            linkedin: null,
            twitter: null
        }
    },
    posizione: {
        citta: null,
        provincia: null,
        indirizzo: null,
        codice_postale: null,
        civico: null
    },
    partita_iva: null,
    certificazioni: [], // solo societa
    gallery: [],
    descrizione: null,
    descrizione_gallery: null,
    sito: null,
    logo: null,
    copertina: null,
    referente: {
        email: null,
        telefono: null,
        nome: null,
        cognome: null
    },
    tipologia: 'Societa',
    categorie: [],
    zone_servite: {
        estero: false,
        italia: false,
        regioni_selezionate: [],
        province_selezionate: []
    },
    eventi: [
        /*
        {
            id: 0,
            titolo: 'Lorem ipsum',
            data_inizio: '',
            data_fine: '',
            descrizione: '',
            banner: 'url',
            posizione: {
                citta: '',
                provincia: '',
                indirizzo: '',
                codice_postale: ''
            }
        }
        */
    ],
    team: { // solo societa
        membri: [
            /*
            {
                nome: '',
                cognome: '',
                ruolo: '',
                link_profilo: '',
                descrizione: '',
                email: '',
                telefono: '',
                immagine: ''
            }
            */
        ]
    },
    formazione: [
        /*
        {
            data_inizio: '',
            data_fine: '',
            titolo: '',
            ente: '',
            descrizione: ''
        }
        */
    ],
    competenze: [
        /*
        {
            descrizione: '',
            tipologia: ''
        }
        */
    ],
    esperienze: [
        /*
        {
            data_inizio: '',
            data_fine: '',
            titolo: '',
            ente: '',
            descrizione: ''
        }
        */
    ],
    billing_end_date: null,
    billing_start_date: null,
    stripe_customer_id: null
});

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from "./components/TheHeader.vue";
import MobileHeader from "./components/MobileHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import AuthenticationPopup from "./components/AuthenticationPopup.vue";
import AnalisiMenuNavbar from "./analisiMenu/AnalisiMenuNavbar.vue";
import PageLoader from "./components/PageLoader.vue";
import { mapMutations } from "vuex";
import storage from "~/assets/js/Storage";
import MobileAnalisiMenu from "./analisiMenu/MobileAnalisiMenu.vue";
import AnalisiMenuSidebar from "./analisiMenu/AnalisiMenuSidebar.vue";

export default {
  name: "AnalisiMenuLayout",
  components: {
    TheHeader,
    MobileHeader,
    TheFooter,
    AuthenticationPopup,
    AnalisiMenuNavbar,
    PageLoader,
    MobileAnalisiMenu,
    AnalisiMenuSidebar,
  },
  beforeMount() {
    addEventListener("error", this.$logger);

    this.setActivePage(this.$route.path);

    // load menu store if not already loaded
    if (!this.$store.state.menu.data) {
      let activeMenuId = this.$localStore.get("activeMenuId");
      let menuData = storage.getMenu(activeMenuId);
      this.initMenu(menuData);
    }

    window.onbeforeunload = (e) => {
      switch (this.$route.name) {
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            e = e || window.event;
            // For IE and Firefox prior to version 4
            if (e) {
              e.returnValue = "Sure?";
            }
            // For Safari
            return "Sure?";
          }
          break;
      }
    };
  },
  beforeDestroy() {
    removeEventListener("error", this.$logger);
  },
  methods: {
    ...mapMutations({
      initMenu: "menu/init",
      setActivePage: "pianificaSidebar/setActivePage",
    }),
    sendLog() {},
  },
};

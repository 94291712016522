import {v4 as uuidv4} from 'uuid'

export const state = () => ({
    categories: [],
    other: null,
    name: null,
    logo: null,
    copertina: null,
    piva: null,
    city: null,
    province: null,
    postalCode: null,
    address: null,
    phone: null,
    email: null,
    refererName: null,
    refererSurname: null,
    refererPhone: null,
    refererEmail: null,
    logoFile: null,
    coverFile: null,
    zone_servite: null,
    is_beta: false
});


export const mutations = {
    set(state, obj) {
        for (const key in obj) {
            if (key in state) {
                state[key] = obj[key];
            }
        }
    }
}


export const actions  = {
    async uploadLogo({ state, commit }) {
        let formData = new FormData();
        formData.append('file', state.logoFile);

        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/0`, formData, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            commit('set', { logo: data.file_name });
        } catch (error) { 
            console.log(error);
        }
    },

    async uploadCover({ state, commit }) {
        let formData = new FormData();
        formData.append('file', state.coverFile);

        console.log(state.coverFile);

        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/1`, formData, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            commit('set', { copertina: data.file_name });
        } catch (error) { 
            console.log(error);
        }
    },

    async complete({ state, commit, dispatch }) {
        const slug = uuidv4();

        commit('partner/set', {
            nome: state.name,
            logo: state.logo,
            copertina: state.copertina,
            categorie: state.categories,
            partita_iva: state.piva,
            posizione: {
                citta: state.city,
                provincia: state.province,
                indirizzo: state.address,
                codice_postale: state.postalCode
            },
            contatti: {
                telefono: state.phone,
                email: state.email
            },
            referente: {
                email: state.refererEmail,
                telefono: state.refererPhone,
                nome: state.refererName,
                cognome: state.refererSurname
            },
            slug: slug,
            zone_servite: state.zone_servite
        }, { root: true });
        
        dispatch('partner/save', null, {root:true});
    }
}

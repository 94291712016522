export default function denormalizeMenu(obj) {
    let menu = {}

    menu.sections = {}
    menu.avg_order = {}

    menu.disabled = obj.disabled

    menu.pubblico = false // Todo
    menu.public_id = null // Todo

    for (const prop in obj) {
        if (['menu_id', 'name', 'pubblico', 'tipo', 'numero_coperti_giornalieri_medio', 'giorni_apertura'].includes(prop)) {
            menu[prop] = obj[prop]
        }
        else if (prop === 'avg_order') {
            for (const sectionId in obj.avg_order) {
                let tmp = sectionId.split('_')
                let sectionIndex = tmp[tmp.length-1]
                menu.avg_order[sectionIndex] = obj.avg_order[sectionId]
            }
        }
        else if (prop === 'sections') {
            for (const sectionId of obj.sections) {
                let section = {}
                
                section.name = obj[sectionId].name
                section.dishes = {}
                section.disabled = obj[sectionId].disabled
                
                let dishIndex = 0
                for (const dishId of obj[sectionId].dishes) {
                    section.dishes[dishIndex] = obj[dishId]
                    dishIndex++
                }

                let tmp = sectionId.split('_')
                let sectionIndex = tmp[tmp.length-1]
                
                menu.sections[sectionIndex] = section
            }
        }
    }
    
    return menu
}
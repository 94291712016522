//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from './components/TheHeader.vue'
import MobileHeader from './components/MobileHeader.vue'
import TheFooter from './components/TheFooter.vue'
import AuthenticationPopup from './components/AuthenticationPopup.vue'
import SupportWidget from '../components/support/SupportWidget.vue'

export default {
    name: 'DefaultLayout',
    components: {
        TheHeader,
        MobileHeader,
        TheFooter,
        AuthenticationPopup,
        SupportWidget
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import TheMenu from '../../components/partners/partials/TheMenu.vue'
	import TheButton from '../../../components/general/TheButton.vue'
	import { mapMutations } from 'vuex'
	import User from '~/assets/js/User'

    export default {
        name: 'PartnerMobileHeader',
        components: {
            TheMenu,
            TheButton
        },
        data() {
            return {
                active: false,
                isAuthenticated: false //to move in store
            }
        },
        computed: {
            username() {
                return this.$localStore.get('pEmail');
            }
		},
		methods: {
            closeMenu() {
                this.active = false;
            },
            signOut() {
                this.$localStore.clearAll();
                this.$cookies.remove('authenticated');
                this.$cookies.remove('pAuthenticated');
                User.signOut();
                window.location.href = '/';
            }
		}
    }

import denormalizeMenu from '~/utilities/denormalizeMenu'
import storage from '~/assets/js/Storage'

export default {
    async save({ state, commit }) {
        // update section status
        for (const sectionId of state.data.sections) {
            let disabled = true
            if (state.data[sectionId].dishes.length) {
                for (const dishId of state.data[sectionId].dishes) {
                    if (state.data[dishId].disabled == false) {
                        disabled = false
                        break
                    }
                }
            }
            commit('setSectionStatus', { id: sectionId, disabled: disabled })
        }

        // update menu status
        let disabled = true
        for (const sectionId of state.data.sections) {
            if (!state.data[sectionId].disabled) {
                disabled = false
                break
            }
        }
        let avgOrderSum = 0
        for (const sectionId in state.data.avg_order) {
            if (!state.data[sectionId].disabled) {
                avgOrderSum += state.data.avg_order[sectionId]
            }
        }
        if (avgOrderSum == 0) {
            disabled = true
        }
        commit('setMenuStatus', disabled)

        console.log(state.data);

        let response = await this.$axios.post(this.$config.apiUrl + this.$config.endpoints.menu.save,
            denormalizeMenu(state.data), 
            { headers: { 'Authorization': 'Bearer ' + this.$localStore.get('accessToken') } })

        if (response.data == null) { // success
            storage.putMenu(state.data)

            // delete old analisys from storage
            storage.deleteAnalysisByMenu(state.data.menu_id)
            
            commit('setEdited', false)
        }
        else { //fail
            console.log(response)
        }
    },

    delete({ state, commit }) {
        this.$axios.delete(this.$config.apiUrl + this.$config.endpoints.menu.delete + '/' + state.data.menu_id,
            { headers: { 'Authorization': 'Bearer ' + this.$localStore.get('accessToken') } }
        )
        .then((response) => {
            // delete menu from local storage
            storage.deleteMenu(state.data.menu_id)

            let menus = storage.getMenuList()

            // change active menu from cookies
            let activeMenuId = menus[0].id
            this.$localStore.set('activeMenuId', activeMenuId)

            // reload store
            let menuData = storage.getMenu(activeMenuId)
            commit('menu/init', menuData, { root: true })

            // update navbar menus
            commit('pianificaNavbar/setMenus', menus, { root: true })

            // close popup
            commit('confirmationPopup/close', null, { root: true })
        })
        .catch((err) => {
            console.log(err)
            // Todo: display error or something
        })
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from '../components/TheHeader.vue'
import PartnerHeader from '../components/partners/TheHeader.vue'
import PartnersSearchSidebar from '../components/partners/searchSidebar.vue'
import DarkFooter from '../components/DarkFooter.vue'
import AuthenticationPopup from '../components/AuthenticationPopup.vue'
import MobileHeader from '../components/partners/MobileHeader.vue'
import SupportWidget from '../../components/support/SupportWidget.vue'
import PartnerMobileHeader from '../components/partners/MobileHeader.vue'
import RegularMobileHeader from '../components/MobileHeader.vue'

export default {
    name: 'PartnersCleanFullWidth',
    components: {
        TheHeader,
        PartnerHeader,
        PartnersSearchSidebar,
        DarkFooter,
        AuthenticationPopup,
        MobileHeader,
        SupportWidget,
        PartnerMobileHeader,
        RegularMobileHeader
    }
}

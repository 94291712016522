export const state = () => ({
    open: false,
    resolvePromise: undefined,
    rejectPromise: undefined
})


export const mutations = {
    open(state) {
        state.open = true
    },
    close(state) {
        state.open = false
    },
    setResolvePromise(state, value) {
        state.resolvePromise = value
    },
    setRejectPromise(state, value) {
        state.rejectPromise = value
    }
}


export const actions  = {
    async getConfirmation({ state, commit }) {
        commit('open')
        return new Promise((resolve, reject) => {
            commit('setResolvePromise', resolve)
            commit('setRejectPromise', reject)
        })
    },
    resolvePromise({state, commit}, value) {
        state.resolvePromise(value)
        commit('close')
    }
}
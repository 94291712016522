import store from "store";

export default class Storage {
  // Restaurant
  static initRestaurant() {
    let restaurants = store.get("restaurants");
    if (!restaurants) {
      restaurants = [];
    }
    store.set("restaurants", restaurants);
  }

  static getRestaurant(restaurantId) {
    let restaurants = store.get("restaurants");
    if (!restaurants) {
      return null;
    }
    for (let i = 0; i < restaurants.length; i++) {
      if (restaurants[i].restaurant_id == restaurantId) {
        return restaurants[i];
      }
    }
  }

  static getRestaurantList() {
    let data = [];
    let restaurants = store.get("restaurants");
    if (!restaurants) {
      restaurants = [];
    }

    for (let i = 0; i < restaurants.length; i++) {
      data.push({
        id: restaurants[i].restaurant_id,
        name: restaurants[i].name,
      });
    }
    return data;
  }

  static putRestaurant(restaurantObj) {
    let restaurants = store.get("restaurants");
    if (!restaurants) {
      restaurants = [];
    }

    let updated = false;
    for (let i = 0; i < restaurants.length; i++) {
      if (restaurants[i].restaurant_id == restaurantObj.restaurant_id) {
        restaurants[i] = restaurantObj;
        updated = true;
        break;
      }
    }

    if (!updated) {
      restaurants.push(restaurantObj);
    }

    store.set("restaurants", restaurants);
  }

  static deleteRestaurant(restaurantId) {
    let restaurants = store.get("restaurants");
    if (!restaurants) {
      return null;
    }

    for (let i = 0; i < restaurants.length; i++) {
      if (restaurants[i].restaurant_id == restaurantId) {
        restaurants.splice(i, 1);
        break;
      }
    }

    store.set("restaurants", restaurants);
  }

  static getFirstFreeRestaurantId() {
    let ids = [];
    let restaurants = store.get("restaurants");
    for (let i = 0; i < restaurants.length; i++) {
      ids.push(parseInt(restaurants[i].restaurant_id));
    }
    let index = 0;
    while (true) {
      if (!ids.includes(index)) {
        return index;
      }
      index++;
    }
  }

  // Menu
  static getMenu(menuId) {
    let menus = store.get("menus");
    if (!menus) {
      return null;
    }
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].menu_id == menuId) {
        return menus[i];
      }
    }
  }

  static getMenuList() {
    let data = [];
    let menus = store.get("menus");
    if (!menus) {
      menus = [];
    }

    for (let i = 0; i < menus.length; i++) {
      data.push({
        id: menus[i].menu_id,
        name: menus[i].name,
      });
    }
    return data;
  }

  static putMenu(menuObj) {
    let menus = store.get("menus");
    if (!menus) {
      menus = [];
    }

    let updated = false;
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].menu_id === menuObj.menu_id) {
        menus[i] = menuObj;
        updated = true;
        break;
      }
    }

    if (!updated) {
      menus.push(menuObj);
    }

    store.set("menus", menus);
  }

  static deleteMenu(menuId) {
    let menus = store.get("menus");
    if (!menus) {
      return null;
    }

    for (let i = 0; i < menus.length; i++) {
      if (menus[i].menu_id == menuId) {
        menus.splice(i, 1);
        break;
      }
    }

    store.set("menus", menus);
  }

  static getFirstFreeMenuId() {
    let ids = [];
    let menus = store.get("menus");
    if (!menus) {
      menus = [];
    }
    for (let i = 0; i < menus.length; i++) {
      ids.push(parseInt(menus[i].menu_id));
    }
    let index = 0;
    while (true) {
      if (!ids.includes(index)) {
        return index;
      }
      index++;
    }
  }

  // Analysis
  static putAnalysis(analysisObj) {
    let analysis = store.get("analysis");
    if (!analysis) {
      analysis = [];
    }

    let updated = false;
    for (let i = 0; i < analysis.length; i++) {
      if (
        analysis[i].restaurant_id == analysisObj.restaurant_id &&
        analysis[i].menu_id == analysisObj.menu_id
      ) {
        analysis[i] = analysisObj;
        updated = true;
        break;
      }
    }

    if (!updated) {
      analysis.push(analysisObj);
    }

    store.set("analysis", analysis);
  }

  static getAnalysis(restaurantId, menuId) {
    let analysiz = store.get("analysis");
    if (!analysiz) {
      return null;
    }
    for (let i = 0; i < analysiz.length; i++) {
      if (
        analysiz[i].restaurant_id == restaurantId &&
        analysiz[i].menu_id == menuId
      ) {
        return analysiz[i];
      }
    }
  }

  static deleteAnalysisByRestaurant(restaurantId) {
    let analysiz = store.get("analysis");
    if (!analysiz) {
      return null;
    }

    for (let i = 0; i < analysiz.length; i++) {
      if (analysiz[i].restaurant_id == restaurantId) {
        analysiz[i] = null;
      }
    }

    analysiz = analysiz.filter((el) => el != null);

    store.set("analysis", analysiz);
  }

  static deleteAnalysisByMenu(menuId) {
    let analysiz = store.get("analysis");
    if (!analysiz) {
      return null;
    }

    for (let i = 0; i < analysiz.length; i++) {
      if (analysiz[i].menu_id == menuId) {
        analysiz[i] = null;
      }
    }

    analysiz = analysiz.filter((el) => el != null);

    store.set("analysis", analysiz);
  }

  // partial Analysis (only menu)
  static putPartialAnalysis(analysisObj) {
    let analysis = store.get("partialAnalysis");
    if (!analysis) {
      analysis = [];
    }

    let updated = false;
    for (let i = 0; i < analysis.length; i++) {
      if (analysis[i].menu_id == analysisObj.menu_id) {
        analysis[i] = analysisObj;
        updated = true;
        break;
      }
    }

    if (!updated) {
      analysis.push(analysisObj);
    }

    store.set("partialAnalysis", analysis);
  }

  static getPartialAnalysis(menuId) {
    let analysiz = store.get("partialAnalysis");
    if (!analysiz) {
      return null;
    }
    for (let i = 0; i < analysiz.length; i++) {
      console.log(analysiz[i]);
      if (analysiz[i].menu_id == menuId) {
        return analysiz[i];
      }
    }
  }

  // Authentication
  static isAuthenticated() {
    return store.get("authenticated");
  }

  // Planner
  static storePlanner(planner) {
    store.set("restaurantPlanner", planner);
  }
  static getPalnner() {
    return store.get("restaurantPlanner");
  }
}

export default (context, inject) => {
    inject('scrollToId', (elementId, headerHeight = null) => {
        let element = document.getElementById(elementId)
        if (!element) { return }

        if (headerHeight == null) {
            let header = document.getElementById('the-header');
            headerHeight = 0;
            if (header) {
                headerHeight = header.offsetHeight;
            }
            headerHeight *= 1.2;
        }

        document.documentElement.scrollTop = window.pageYOffset + element.getBoundingClientRect().top - (headerHeight);
    })
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import User from '~/assets/js/User'
import normalizeRestaurant from '~/utilities/normalizeRestaurant'
import normalizeMenu from '~/utilities/normalizeMenu'
import storage from '~/assets/js/Storage'
import braceria from '~/utilities/presets/menu/braceria.js'
import pizzeria from '~/utilities/presets/menu/pizzeria.js'
import trattoria from '~/utilities/presets/menu/trattoria.js'

export default {
	name: 'AuthenticationPopup',
	data() {
		return {
			authenticationForm: {
				username: null,
				password: null,
				remember: false
			},
			registrationForm: {
				email: null,
				username: null,
				password: null,
				passwordConfirmation: null,
				privacy: false
			},
			forgotPasswordForm: {
				username: null
			},
			loading: false,
			loadingCompleted: true,
			registrationSuccess: null,
			error: {
				code: null,
				message: null
			},
			forgotPasswordResult: null,
			popup: null
		}
	},
	computed: {
		open() {
			return this.$store.state.authPopup.open
		},
		tab() {
			return this.$store.state.authPopup.tab
		}
	},
	methods: {
		...mapMutations({
			toggle: 'authPopup/toggle',
			switchTab: 'authPopup/switchTab',
			openForgotPassword: 'authPopup/openForgotPassword',
			initRestaurant: 'restaurant/init',
			initMenu: 'menu/init'
		}),
		redirectToGoogle() {
			this.popup = window.open(this.$config.googleRedirectLink, "mywindow","popup,menubar=1,resizable=1,width=420,height=600");
		},
		authenticate() {
			this.error.code = null;
			this.error.message = null;
			this.loading = true;
			this.loadingCompleted = false;
			
			User.authenticate(this.authenticationForm.username, this.authenticationForm.password)
			.then(result => {
				console.log(result);

				this.$localStore.clearAll();
				this.$cookies.remove('authenticated');
				this.$cookies.remove('pAuthenticated');

				User.getUserAttributes().then(attributes => {
					console.log(attributes);
					let showTutorial = true;
					attributes.forEach(attribute => {
						if (attribute.getName() == 'custom:show_tutorial') {
							showTutorial = false;
						}
					});
					this.$localStore.set('showTutorial', showTutorial);
				});

				this.$localStore.set('email', result.idToken.payload.email);
				this.$localStore.set('username', result.idToken.payload['cognito:username']);
				this.$localStore.set('accessToken', result.getAccessToken().getJwtToken());
				this.$localStore.set('refreshToken', result.getRefreshToken().getToken());
				this.$localStore.set('authenticated', true);

				this.$cookies.set('authenticated', true);

				// Request user configuration
				this.$axios.get(this.$config.apiUrl + this.$config.endpoints.initRequest, {
					headers: { 'Authorization': 'Bearer ' + this.$localStore.get('accessToken') }
				})
				.then((response) => {
					let data = response.data

					// Normalize and save restaurants
					data.restaurants.forEach((restaurantData, i) => {
						let normalizedRestaurant = normalizeRestaurant(restaurantData)
						storage.putRestaurant(normalizedRestaurant)
						if (i === 0) {
							this.$localStore.set('activeRestaurantId', restaurantData.restaurant_id);
							this.initRestaurant(normalizedRestaurant)
						}
					})

					if (!data.restaurants.length) {
						storage.initRestaurant()
					}

					// Normalize and save menu
					data.menus.forEach((menuData, i) => {
						let normalizedMenu = normalizeMenu(menuData)
						storage.putMenu(normalizedMenu)
						if (i === 0) {
							this.$localStore.set('activeMenuId', menuData.menu_id)
							this.initMenu(normalizedMenu)
						}
					})

					data.analysis.forEach(analysisData => {
						storage.putAnalysis(analysisData)
					})

					this.loading = false
					this.toggle()
					window.location.href = '/pianifica/locale'
				})
				.catch((err) => {
					console.dir(err)
				})
			})
			.catch(err => {
				console.dir(err)

				this.error.code = err.code;

				switch (err.code) {
					case 'UserNotFoundException':
						this.error.message = 'Username o Password errati';
						break;
					case 'UserNotConfirmedException':
						this.error.message = 'Utente non confermato';
						break;
					case 'NotAuthorizedException':
						this.error.message = 'Username o Password errati';
						break;
					default:
						this.error.message = 'Si è verificato un errore, riprova più tardi';
						break;
				}
				
				this.loading = false;
			})
		},
		registrate() {
			this.error.code = null;
			this.error.message = null;

			// Check password confirmation
			if (this.registrationForm.password !== this.registrationForm.passwordConfirmation) {
				this.error.code = 'PasswordMissmatch';
				this.error.message = 'Le Password non corrispondono';
				return;
			}

			this.loading = true
			this.loadingCompleted = false

			User.registrate(this.registrationForm.username, this.registrationForm.password, this.registrationForm.email)
			.then(result => {
				this.loading = false
				console.log(result)
				this.registrationSuccess = 'Abbiamo inviato una mail di conferma al tuo indirizzo'
			})
			.catch(err => { 
				console.dir(err)

				this.error.code = err.code;

				if (err.code == 'InvalidParameterException') {
					// Email format not valid
					if (err.message == 'Invalid email address format.') {
						this.error.message = 'Email non valida';
					}
					else if (err.message.includes("'username'")) {
						this.error.message = 'Il nome utente non può contenere spazi';
					}
					else if (err.message.includes("'password'")) {
						this.error.message = 'La Password non è abbastanza sicura, deve contenere almeno una lettera maiuscola e un numero';
					}
				}

				if (err.code == 'InvalidPasswordException') {
					// Password is not long enough
					if (err.message == 'Password did not conform with policy: Password not long enough') {
						this.error.message = 'La Password è troppo corta';
					}
					// Password does not container an uppercase character
					if (err.message == 'Password did not conform with policy: Password must have uppercase characters') {
						this.error.message = 'La Password deve contenere almeno una lettera maiuscola';
					}
					// Password does not container an uppercase character
					if (err.message == "Password did not conform with policy: Password must have lowercase characters") {
						this.error.message = 'La Password deve contenere almeno una lettera minuscola';
					}
					// Password does not contain a number
					if (err.message == 'Password did not conform with policy: Password must have numeric characters') {
						this.error.message = 'La Password deve contenere almeno un numero';
					}
				}
				if (err.code == 'UsernameExistsException') {
					// Username already in use
					if (err.message == 'User already exists') {
						this.error.message = 'Il nome utente non è disponibile';
					}
				}
				if (err.code == 'UserLambdaValidationException') {
					// email already in use
					this.error.message = 'L\' email non è disponibile';
				}
				this.loading = false;
			})
		},
		switchTabWrp() {
			this.authenticationForm.username = null;
			this.authenticationForm.password = null;
			this.registrationForm.email = null;
			this.registrationForm.username = null;
			this.registrationForm.password = null;
			this.registrationForm.passwordConfirmation = null;
			this.registrationForm.privacy = null;
			this.error.code = null;
			this.error.message = null;
			this.registrationSuccess = null;

			this.switchTab();
		},
		resendConfirmationCode() {
			User.resendConfirmationCode(this.authenticationForm.username);
		},
		forgotPassword() {
			User.forgotPassword(this.forgotPasswordForm.username);
			this.forgotPasswordResult = true;
		},
		sendForgotPassword() {
			this.forgotPasswordResult = true;
		}
	}
}

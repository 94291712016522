//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import { mapMutations } from 'vuex'
    import NewsEditPanel from '../../../components/partners/panels/edits/News.vue'
    import ServedAreasEditPanel from '../../../components/partners/panels/edits/ServedAreas.vue'
    import CategoriesEditPanel from '../../../components/partners/panels/edits/Categories.vue'
    import ContactsEditPanel from '../../../components/partners/panels/edits/Contacts.vue'
    import MainEditPanel from '../../../components/partners/panels/edits/Main.vue'
    import CertificationsEditPanel from '../../../components/partners/panels/edits/Certifications.vue'
    import GalleryEditPopup from '../../../components/partners/panels/edits/Gallery.vue'
    import TrainingEditPanel from '../../../components/partners/panels/edits/Training.vue'
    import SkillsEditPanel from '../../../components/partners/panels/edits/Skills.vue'
    import ExperiencesEditPanel from '../../../components/partners/panels/edits/Experiences.vue'
    import EventsEditPanel from '../../../components/partners/panels/edits/Events.vue'
    import TeamEditPanel from '../../../components/partners/panels/edits/Team.vue'

    export default {
        name: 'PartnerEditPopup',
        components: {
            MainEditPanel,
            NewsEditPanel,
            ServedAreasEditPanel,
            CategoriesEditPanel,
            ContactsEditPanel,
            CertificationsEditPanel,
            GalleryEditPopup,
            TrainingEditPanel,
            EventsEditPanel,
            TeamEditPanel,
            SkillsEditPanel,
            ExperiencesEditPanel
        },
        computed: {
            active: function() { return this.$store.state.panelEditPopup.active },
            panelId: function() { return this.$store.state.panelEditPopup.panelId }
        },
        methods: {
            ...mapMutations({
                close: 'panelEditPopup/close'
            }),
            save(_callback) {
                if (_callback) {
                    _callback();
                }
            }
        }
    }

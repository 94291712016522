export const state = () => ({
    loading: false
})
    
export const mutations = {
    toggle(state) {
        state.loading = !state.loading
    },
    open(state, duration = null) {
        state.loading = true
        if (duration) {
            setTimeout(() => this.commit('pageLoader/close'), duration)
        }
    },
    close(state) {
        state.loading = false
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {v4 as uuidv4} from 'uuid'

export default {
    name: 'CertificationsEditPanel',
    data() {
        return {
            loading: false,
            certifications: [],
            showErrors: false,
            uploadError: false
        }
    },
    computed: {
        certArr() {
            let arr = [];
            for (const k in this.files) {
                arr.push(this.files[k]);
            }
            return arr;
        }
    },
    created() {
        this.$parent.save = this.save;
        
        this.$store.state.partner.certificazioni.forEach(certification => this.certifications.push(certification));
    },
    methods: {

        remove(key) {
            this.certifications.splice(key, 1);
        },

        async save() {
            // Save on vuex
            this.$store.commit('partner/emptyCertifications');

            this.certifications.forEach(certification => {
                this.$store.commit('partner/pushCertification', certification);
            });

            // Dispatch vuex save
            await this.$store.dispatch('partner/saveCertifications');
            this.$store.commit('panelEditPopup/close');
        },

        async getFileObject(e){
            this.loading = true;
            this.uploadError = false;

            try {
                await Promise.all([...e.target.files].map(async (file) => {
                    let formData = new FormData();
                    formData.append('file', file);
                    const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/${uuidv4()}`, formData, { 
                        headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
                    });
                    this.certifications.push(data.file_name);
                }));
                this.$refs.fileInput.value = "";
            }
            catch (error) {
                console.log(error);
                this.uploadError = true;
            }
            
            this.loading = false;
        }

    }
}

//
//
//
//
//
//
//

import storage from '~/assets/js/Storage';

export default {
	name: 'PartnerMenu',
	computed: {
		authenticated() {
			return storage.isAuthenticated()
		}
	},
	methods: {
		closeMobile() {
			if (this.$parent.closeMenu) {
				this.$parent.closeMenu();
			}
		}
	}
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from "./components/TheHeader.vue";
import MobileHeader from "./components/MobileHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import AuthenticationPopup from "./components/AuthenticationPopup.vue";
import PianificaSidebar from "./components/PianificaSidebar.vue";
import PianificaNavbar from "./components/PianificaNavbar.vue";
import MobilePianificaMenu from "./components/MobilePianificaMenu.vue";
import PageLoader from "./components/PageLoader.vue";
import { mapMutations } from "vuex";
import storage from "~/assets/js/Storage";

export default {
  name: "DefaultLayout",
  components: {
    TheHeader,
    MobileHeader,
    TheFooter,
    AuthenticationPopup,
    PianificaSidebar,
    PianificaNavbar,
    PageLoader,
    MobilePianificaMenu,
  },
  beforeMount() {
    addEventListener("error", this.$logger);

    this.setActivePage(this.$route.path);

    // load restaurant store if not already loaded
    if (!this.$store.state.restaurant.data) {
      let activeRestaurantId = this.$localStore.get("activeRestaurantId");
      let restaurantData = storage.getRestaurant(activeRestaurantId);
      this.initRestaurant(restaurantData);
    }

    // load menu store if not already loaded
    if (!this.$store.state.menu.data) {
      let activeMenuId = this.$localStore.get("activeMenuId");
      let menuData = storage.getMenu(activeMenuId);
      this.initMenu(menuData);
    }

    window.onbeforeunload = (e) => {
      switch (this.$route.name) {
        case "pianifica-locale":
          if (this.$store.state.restaurant.edited) {
            e = e || window.event;
            // For IE and Firefox prior to version 4
            if (e) {
              e.returnValue = "Sure?";
            }
            // For Safari
            return "Sure?";
          }
          break;
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            e = e || window.event;
            // For IE and Firefox prior to version 4
            if (e) {
              e.returnValue = "Sure?";
            }
            // For Safari
            return "Sure?";
          }
          break;
      }
    };
  },
  beforeDestroy() {
    removeEventListener("error", this.$logger);
  },
  methods: {
    ...mapMutations({
      setActivePage: "pianificaSidebar/setActivePage",
      initRestaurant: "restaurant/init",
      initMenu: "menu/init",
    }),
    sendLog() {},
  },
  watch: {
    $route(to, from) {
      this.setActivePage(to.path);
    },
  },
};

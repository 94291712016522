//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PartnerHeader from '../components/partners/TheHeader.vue'
import MobileHeader from '../components/partners/MobileHeader.vue'
import PartnersSidebar from '../components/partners/sidebar.vue'
import PartnerEditPopup from '../components/partners/EditPopup.vue'
import PartnerMobileSidebar from '../components/partners/MobileSidebar.vue'
import SupportWidget from '../../components/support/SupportWidget.vue'

export default {
    name: 'PartnersPrivate',
    components: {
        PartnerHeader,
        MobileHeader,
        PartnersSidebar,
        PartnerEditPopup,
        PartnerMobileSidebar,
        SupportWidget
    }
}

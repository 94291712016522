export default function normalizeRestaurant(obj) {
    let normalizedObj = {}

    for (const property in obj) {

        if (typeof obj[property] === 'undefined') { continue }

        // if property is not an array or object
        if (typeof obj[property] !== 'object') {
            normalizedObj[property] = obj[property]
            continue
        }

        if (property == 'tavoli') {
            normalizedObj[property] = []
            let index = 0
            for (const item of obj[property]) {
                normalizedObj[property].push(property+'_'+index)
                normalizedObj[property+'_'+index] = item
                index++
            }
        }

        if (property == 'sections') {
            normalizedObj['sections'] = []
            for (const section of obj['sections']) {
                let propertyName = 'section_'+section.id
                normalizedObj['sections'].push(propertyName)

                normalizedObj[propertyName] = {}

                normalizedObj[propertyName].id = section.id
                normalizedObj[propertyName].name = section.name
                normalizedObj[propertyName].price = section.price
                normalizedObj[propertyName].subsections = []
                
                for (const subsection of section.subsections) {
                    let propertySubName = 'subsection_'+section.id+'_'+subsection.id
                    normalizedObj[propertyName].subsections.push(propertySubName)

                    normalizedObj[propertySubName] = {}

                    normalizedObj[propertySubName].id = subsection.id
                    normalizedObj[propertySubName].name = subsection.name
                    normalizedObj[propertySubName].price = subsection.price
                    normalizedObj[propertySubName].objects = []
                    
                    let index = 0
                    for (const object of subsection.objects) {
                        let propertyObjName = 'object_'+section.id+'_'+subsection.id+'_'+index
                        normalizedObj[propertySubName].objects.push(propertyObjName)

                        normalizedObj[propertyObjName] = {}
                        normalizedObj[propertyObjName].name = object.name
                        normalizedObj[propertyObjName].price = object.price
                        normalizedObj[propertyObjName].quantity = object.quantity

                        index++
                    }
                }
            }
        }
    }

    return normalizedObj
}
export default{

    set(state, obj) {
        for (const key in obj) {
            if (key in state) {
                state[key] = obj[key];
            }
        }
    },

    setFiles(state, obj) {
        for (const key in obj) {
            if (key in state) {
                state.files[key] = obj[key];
            }
        }
    },

    setEvents(state, arr) {
        state.eventi = arr;
    },

    pushCertification(state, url) {
        if (state.certificazioni == null) {
            state.certificazioni = [];
        }
        state.certificazioni.push(url);
    },

    emptyCertifications(state) {
        state.certificazioni.splice(0);
    },

    pushImage(state, url) {
        if (state.gallery == null) {
            state.gallery = [];
        }
        state.gallery.push(url);
    },

    emptyGallery(state) {
        state.gallery.splice(0);
    }

}

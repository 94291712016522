//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
import TheButton from "../../components/general/TheButton.vue";
import storage from "~/assets/js/Storage";
import newMenu from "~/utilities/presets/menu/newMenu";
import NotificationCenter from "../components/NotificationCenter.vue";

export default {
  name: "PianificaNavbar",
  components: {
    TheButton,
    NotificationCenter,
  },
  created() {
    if (!process.client) {
      return;
    }

    // populate select
    this.setNavbarRestaurants(storage.getRestaurantList());
    this.setNavbarMenus(storage.getMenuList());
  },
  methods: {
    ...mapMutations({
      initRestaurant: "restaurant/init",
      initMenu: "menu/init",
      initAnalysis: "analysis/init",
      setNavbarRestaurants: "pianificaNavbar/setRestaurants",
      setNavbarMenus: "pianificaNavbar/setMenus",
      openLoader: "pageLoader/open",
      setMenuEdited: "menu/setEdited",
      setRestaurantEdited: "restaurant/setEdited",
    }),
    async loadRestaurantStore(event) {
      switch (this.$route.name) {
        // from restaurant page
        case "pianifica-locale":
          // if restaurant is edited
          if (this.$store.state.restaurant.edited) {
            // show exit popup
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // calse close
            if (confirmed === null) {
              // reset select value and return
              event.target.value = this.$localStore.get("activeRestaurantId");
              return;
            }
            // case save
            if (confirmed) {
              // save restaurant
              await this.$store.dispatch("restaurant/save");
            } else {
              // delete changes
              let restaurantData = storage.getRestaurant(
                this.$store.state.restaurant.data.restaurant_id
              );
              this.initRestaurant(restaurantData);
              this.setRestaurantEdited(false);
            }
          }
          break;
        // from menu page
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            // show exit popup
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // calse close
            if (confirmed === null) {
              // reset select value and return
              event.target.value = this.$localStore.get("activeRestaurantId");
              return;
            }
            // case save
            if (confirmed) {
              // save restaurant
              await this.$store.dispatch("menu/save");
            } else {
              // delete changes
              let menuData = storage.getMenu(
                this.$store.state.menu.data.menu_id
              );
              this.initMenu(menuData);
              this.setMenuEdited(false);
            }
          }
          break;
      }

      // load menu and change page
      let restaurantId = event.target.value;

      this.openLoader(500);

      // load restaurant
      let restaurantData = storage.getRestaurant(restaurantId);
      this.initRestaurant(restaurantData);

      // set active restaurant id
      this.$localStore.set("activeRestaurantId", restaurantId);

      // redirect to restaurant page
      this.$router.push({ path: "/pianifica/locale" });
    },
    async loadMenuStore(event) {
      switch (this.$route.name) {
        // from restaurant page
        case "pianifica-locale":
          // if restaurant is edited
          if (this.$store.state.restaurant.edited) {
            // show exit popup
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // calse close
            if (confirmed === null) {
              // reset select value and return
              event.target.value = this.$localStore.get("activeMenuId");
              return;
            }
            // case save
            if (confirmed) {
              // save restaurant
              await this.$store.dispatch("restaurant/save");
            } else {
              // delete changes
              let restaurantData = storage.getRestaurant(
                this.$store.state.restaurant.data.restaurant_id
              );
              this.initRestaurant(restaurantData);
              this.setRestaurantEdited(false);
            }
          }
          break;
        // from menu page
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            // show exit popup
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // calse close
            if (confirmed === null) {
              // reset select value and return
              event.target.value = this.$localStore.get("activeMenuId");
              return;
            }
            // case save
            if (confirmed) {
              // save restaurant
              await this.$store.dispatch("menu/save");
            } else {
              // delete changes
              let menuData = storage.getMenu(
                this.$store.state.menu.data.menu_id
              );
              this.initMenu(menuData);
              this.setMenuEdited(false);
            }
          }
          break;
      }

      // load menu and change page
      let menuId = event.target.value;

      this.openLoader(500);

      // load menu
      let menuData = storage.getMenu(menuId);
      this.initMenu(menuData);

      // set active menu id
      this.$localStore.set("activeMenuId", menuId);

      // redirect to menu page
      this.$router.push({ path: "/pianifica/menu" });
    },
    async createMenu() {
      if (storage.getMenuList().length >= 10) {
        this.$store.dispatch("notificationCenter/notify", {
          text: "Hai già raggiunto il numero massimo di menu!",
          status: false,
        });
        return;
      }

      this.$router.push("/pianifica/menu/nuovo");
    },
    createRestaurant() {
      if (storage.getRestaurantList().length >= 10) {
        this.$store.dispatch("notificationCenter/notify", {
          text: "Hai già raggiunto il numero massimo di locali!",
          status: false,
        });
        return;
      }

      this.$router.push("/pianifica/locale/nuovo");
    },
    async requestAnalysis() {
      if (!storage.getMenuList().length) {
        this.$router.push({ path: "/pianifica/menu/nuovo" });
        return;
      }

      switch (this.$route.name) {
        case "pianifica-locale":
          if (this.$store.state.restaurant.edited) {
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // stay on current page
            if (confirmed === null) {
              return;
            }

            // save and change page
            if (confirmed) {
              await this.$store.dispatch("restaurant/save");
            } else {
              // delete changes
              let restaurantData = storage.getRestaurant(
                this.$store.state.restaurant.data.restaurant_id
              );
              this.initRestaurant(restaurantData);
              this.setRestaurantEdited(false);
            }
          }
          break;
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // stay on current page
            if (confirmed === null) {
              return;
            }

            // save and change page
            if (confirmed) {
              await this.$store.dispatch("menu/save");
            } else {
              // delete changes
              let menuData = storage.getMenu(
                this.$store.state.menu.data.menu_id
              );
              this.initMenu(menuData);
              this.setMenuEdited(false);
            }
          }
          break;
      }

      // empty analysis if menu is disabled
      if (this.$store.state.menu.data.disabled) {
        this.initAnalysis(null);
        this.$router.push("/pianifica/analisi");
      } else {
        // if analysis is in storage
        let analysis = storage.getAnalysis(
          this.$localStore.get("activeRestaurantId"),
          this.$localStore.get("activeMenuId")
        );

        if (analysis) {
          this.initAnalysis(analysis);
          this.$router.push("/pianifica/analisi");
        } else {
          // request analisys
          this.$store.dispatch("analysis/request");
        }
      }
    },
  },
};

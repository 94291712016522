export const state = () => ({
  activePage: null,
  activeSection: null,
  restaurant: {
    label: "Locale",
    order: [
      "gestioneLocale",
      "composizioneSala",
      "costiCucina",
      "costiSala",
      "costiAltreSpese",
    ],
    sections: {
      gestioneLocale: {
        label: "Gestione",
      },
      composizioneSala: {
        label: "Composizione Sala",
      },
      costiCucina: {
        label: "Costi cucina",
      },
      costiSala: {
        label: "Costi sala",
      },
      costiAltreSpese: {
        label: "Costi altre spese",
      },
    },
  },
  menu: {
    label: "Menu",
    order: ["tipoMenu", "vociMenu", "ordineTipico"],
    sections: {
      tipoMenu: {
        label: "Tipo di menu",
      },
      vociMenu: {
        label: "Voci del menu",
      },
      ordineTipico: {
        label: "Ordine tipico",
      },
    },
  },
  analysis: {
    label: "Analisi",
    order: [
      "pannelloControllo",
      "costiRicavi",
      "analisiScenari",
      "percentuali",
      "analisiMenu",
    ],
    sections: {
      pannelloControllo: {
        label: "Pannello di controllo",
      },
      costiRicavi: {
        label: "Costi e ricavi",
      },
      analisiScenari: {
        label: "Analisi a scenari",
      },
      percentuali: {
        label: "Percentuali",
      },
      analisiMenu: {
        label: "Analisi del menu",
      },
    },
  },
  menuAnalysis: {
    label: "Menu",
    order: ["gestioneLocale", "vociMenu", "ordineTipico"],
    sections: {
      gestioneLocale: {
        label: "Gestione del locale",
      },
      vociMenu: {
        label: "Voci del menu",
      },
      ordineTipico: {
        label: "Ordine tipico",
      },
    },
  },
  partialAnalysis: {
    label: "Analisi",
    order: ["analisiRicavo", "performanceDeiPiatti"],
    sections: {
      analisiRicavo: {
        label: "Analisi Ricavo",
      },
      performanceDeiPiatti: {
        label: "Performance dei piatti",
      },
    },
  },
});

export const mutations = {
  setActivePage(state, routePath) {
    routePath = routePath.endsWith("/") ? routePath.slice(0, -1) : routePath;

    console.log(routePath);

    switch (routePath) {
      case "/pianifica/locale":
        state.activePage = "restaurant";
        state.activeSection = "gestioneLocale";
        break;
      case "/pianifica/menu":
        state.activePage = "menu";
        state.activeSection = "tipoMenu";
        break;
      case "/pianifica/analisi":
        state.activePage = "analysis";
        state.activeSection = "pannelloControllo";
        break;
      case "/analisi-menu":
        state.activePage = "menuAnalysis";
        state.activeSection = "gestioneLocale";
        break;
      case "/analisi-menu/analisi":
        state.activePage = "partialAnalysis";
        state.activeSection = "analisiRicavo";
        break;
    }
  },
  setActiveSection(state, sectionId) {
    state.activeSection = sectionId;
  },
};

export const actions = {
  bindScroll({ state, commit }) {
    let activePage = state.activePage;

    if (typeof state[activePage] === "undefined") {
      return;
    } //DEBUG me sa che non serve a nulla

    let sections = state[activePage].order;

    window.addEventListener("scroll", () => {
      // Problema, viene chiamato mille volte
      for (const i in sections) {
        let element = document.querySelector(`#${sections[i]}`);
        if (element) {
          let pos = element.getBoundingClientRect();
          let sectionPxFromTop = pos.top;

          let marginTop = window.innerHeight * 0.3;

          if (sectionPxFromTop <= marginTop) {
            commit("setActiveSection", sections[i]);
          } else {
            break;
          }
        }
      }
    });
  },
};

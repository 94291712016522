import storage from "~/assets/js/Storage";

export default function ({ route, redirect }) {
  if (publicRoutes.includes(route.name)) {
    if (storage.isAuthenticated()) {
      return redirect("/ristoratori");
    }
  } else {
    if (!storage.isAuthenticated()) {
      return redirect("/ristoratori/autenticazione");
    }
  }
}

const publicRoutes = [
  "ristoratori-autenticazione",
  "ristoratori-autenticazione-registrazione",
  "ristoratori-autenticazione-recupero-password",
  "ristoratori-autenticazione-conferma",
  "consulenza",
  "consulenza-richiesta",
  "consulenza-grazie",
  "consulenza-condizioni-generali-di-contratto",
];

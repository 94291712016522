//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'PartnersSidebar',
    data() {
        return {
            isSticky: true
        }
    },
    computed: {
        slug() {
            return this.$store.state.partner.slug;
        },
        billingStartDate() {
            let date = new Date(this.$store.state.partner.billing_start_date);
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            return date.toLocaleDateString('it-IT', options);
        },
        billingEndDate() {
            let date = new Date(this.$store.state.partner.billing_end_date);
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            return date.toLocaleDateString('it-IT', options);;
        }
    },
    beforeMount () {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.handleScroll();
        console.log( this.$store.state.partner.billing_end_date);
    },
    methods: {
        handleScroll () {
            const stickyHeight = this.$refs.sticky.offsetHeight;
            const wrpEndfromTop = this.$refs.stickyWrp.getBoundingClientRect().bottom;
            this.isSticky = wrpEndfromTop > (stickyHeight + 75 + 40);
        }
    }
}

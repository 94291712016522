export default {
  pushPartner(state, partnerId) {
    state.partners.push(partnerId);
  },
  removePartner(state, partnerId) {
    const index = state.partners.indexOf(partnerId);
    if (index !== -1) {
      state.partners.splice(index, 1);
    }
  },
  setPartners(state, partnerIds) {
    this.removeAllPartners();
    for (const partnerId of partnerIds) {
      this.state.partners.push(partnerId);
    }
  },
  removeAllPartners(state) {
    while (state.partners.length > 0) {
      state.partners.pop();
    }
  },
};

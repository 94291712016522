//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

export default {
    name: 'TrainingEditPanel',
    data() {
        return {
            items: [],
            highlight: false
        }
    },
    created() {
        this.$parent.save = this.save;

        const items = this.$store.state.partner.formazione;
        if (items) {
            for (const item of items) {
                this.items.push({
                    institution: item.ente,
                    title: item.titolo,
                    description: item.descrizione,
                    startDate: item.data_inizio,
                    endDate: item.data_fine,
                    open: false
                });
            }
        }
    },
    computed: {
        formazione() {
            let arr = [];
            for (const item of this.items) {
                arr.push({
                    data_inizio: moment(item.startDate).format('YYYY-MM-DD'),
                    data_fine: moment(item.endDate).format('YYYY-MM-DD'),
                    titolo: item.title,
                    ente: item.institution,
                    descrizione: item.description
                });
            }
            return arr;
        }
    },
    methods: {
        
        toggle(i) {
            if (!this.items[i].open) {
                for (const k in this.items) {
                    this.items[k].open = false;
                }
                this.items[i].open = true;
            }
            else {
                this.items[i].open = false;
            }
        },

        add() {
            for (const k in this.items) {
                this.items[k].open = false;
            }

            this.items.push({
                institution: null,
                title: 'Nuova qualifica',
                description: null,
                startDate: null,
                endDate: null,
                open: true
            });
        },

        remove(index) {
            this.items.splice(index, 1);
        },

        async save() {
            if (!this.validateInput()) { return; }
            this.$store.commit('partner/set', { formazione: this.formazione });
            await this.$store.dispatch('partner/saveTraining');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            for (const item of this.items) {
                if (!item.institution || item.institution == '' ||
                    !item.title || item.title == ''
                ) { 
                    this.highlight = true;
                    return false;
                }
            }
            return true;
        },

        checkDateValidity(e, i) {
            const startDate = this.items[i].startDate;
            const endDate = this.items[i].endDate;
            
            if (!startDate || !endDate) {
                return e.target.classList.remove('border-red');
            }

            if(moment(startDate).isBefore(endDate)) {
                return e.target.classList.remove('border-red');
            }

            e.target.classList.add('border-red');
        }

    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from './components/TheHeader.vue'
import MobileHeader from './components/MobileHeader.vue'
import TheFooter from './components/TheFooter.vue'
import AuthenticationPopup from './components/AuthenticationPopup.vue'
import PartnerHeader from './components/partners/TheHeader.vue'
import PartnerMobileHeader from './components/partners/MobileHeader.vue'

export default {
    name: 'DefaultLayout',
    components: {
        TheHeader,
        MobileHeader,
        TheFooter,
        AuthenticationPopup,
        PartnerHeader,
        PartnerMobileHeader
    }
}

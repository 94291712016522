//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PlannerCompletionDot",
  props: {
    completed: {
      type: Boolean,
      required: true,
    },
  },
};

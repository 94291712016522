export default function denormalizeRestaurant(obj) {

    let normalizedProps = [
        'name',
        'affitto',
        'pubblico',
        'dimensione',
        'tipo_analisi',
        'ore_servizio',
        'restaurant_id',
        'posti_a_sedere',
        'giorni_apertura',
        'ore_rotazione_tavolo'
    ]
    
    let restaurant = {}

    restaurant.sections = []
    restaurant.tavoli = []

    for (const prop in obj) {
        if (normalizedProps.includes(prop)) {
            restaurant[prop] = obj[prop]
        }
        else if (prop === 'sections') {
            for (const sectionId of obj.sections) {
                let section = {}
                
                section.id = obj[sectionId].id
                section.name = obj[sectionId].name
                section.price = 7777777
                section.subsections = []
                
                for (const subsectionId of obj[sectionId].subsections) {
                    
                    let subsection = {}
                    
                    subsection.id = obj[subsectionId].id
                    subsection.name = obj[subsectionId].name
                    subsection.objects = []
                    subsection.price = 0
                    
                    for (const objectId of obj[subsectionId].objects) {
                        
                        let object = {}
                    
                        object.name = obj[objectId].name
                        object.price = obj[objectId].price
                        object.quantity = obj[objectId].quantity

                        subsection.price += object.price * object.quantity
                    
                        subsection.objects.push(object)
                    }
                    section.subsections.push(subsection)
                }
                
                restaurant.sections.push(section)
            }
        }
        else if (prop === 'tavoli') {
            for (const tableId of obj.tavoli) {
                let table = {}
                table.dimensione = obj[tableId].dimensione
                table.quantita = obj[tableId].quantita
                restaurant.tavoli.push(table)
            }
        }
    }

    return restaurant
}
export default {
    getSeats(state) {
        let seats = 0
        state.data.tavoli.forEach(tableId => {
            seats += state.data[tableId].quantita * state.data[tableId].dimensione
        })
        return parseInt(seats)
    },
    getTotalSeats(state) {
        return parseInt(state.data.dimensione / 3)
    }
}
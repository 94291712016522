//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SideBanner'
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from '../components/TheHeader.vue'
import PartnerHeader from '../components/partners/TheHeader.vue'
import PartnersSearchSidebar from '../components/partners/searchSidebar.vue'
import DarkFooter from '../components/DarkFooter.vue'
import AuthenticationPopup from '../components/AuthenticationPopup.vue'
import SupportWidget from '../../components/support/SupportWidget.vue'

export default {
    name: 'PartnersClean',
    components: {
        TheHeader,
        PartnerHeader,
        PartnersSearchSidebar,
        DarkFooter,
        AuthenticationPopup,
        SupportWidget
    },
    data () {
        return {
            isPartner: this.$localStore.get('pAuthenticated')
        }
    }
}

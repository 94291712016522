//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import TheButton from '../../../components/general/TheButton.vue'
	import User from '~/assets/js/User'

    export default {
        name: 'MobileAuthenticationHeader',
        components: {
            TheButton
        },
        data() {
            return {
                active: false,
                isAuthenticated: false //to move in store
            }
        },
        computed: {
			autenticated() {
				return this.$localStore.get('authenticated')
			},
            username() {
                return this.$localStore.get('name') ? this.$localStore.get('name') : this.$localStore.get('username')
            },
            welcomeMessage() {
                let date = new Date()
                let currentHour = date.getHours()
                return currentHour > 12 ? 'Buonasera' : 'Buongiorno'
            }
		},
		methods: {
            closeMenu() {
                this.active = false;
            },
            signOut() {
                this.$localStore.clearAll();
                this.$cookies.remove('authenticated');
                User.signOut();
                window.location.href = '/';
            }
		}
    }

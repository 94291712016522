export default (context, inject) => {
  inject("scrollTo", (querySelector, offset = 0) => {
    const element = document.querySelector(querySelector);

    if (!element) {
      return;
    }

    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  });
};

import Vue from 'vue'

export default{
    init(state, data) {
        state.data = data
    },
    setEdited(state, bool) {
        state.edited = bool
    },
    updateDishFavorite(state, data) {
        data.favorite = Boolean(data.favorite)
        state.data[data.dishId].favorite = data.favorite
        state.edited = true
    },
    updateDishCost(state, data) {
        data.cost = parseFloat(data.cost)
        state.data[data.dishId].cost = data.cost
        if (data.cost <= 0 || state.data[data.dishId].price <= 0) {
            state.data[data.dishId].disabled = true
        } else {
            state.data[data.dishId].disabled = false
        }
        state.edited = true
    },
    updateDishPrice(state, data) {
        data.price = parseFloat(data.price)
        state.data[data.dishId].price = data.price
        if (data.price <= 0 || state.data[data.dishId].cost <= 0) {
            state.data[data.dishId].disabled = true
        } else {
            state.data[data.dishId].disabled = false
        }
        state.edited = true
    },
    updateDishName(state, data) {
        state.data[data.dishId].name = data.name
        state.edited = true
    },
    deleteDish(state, dishId) {
        // Delete dish from section.dishes array
        let tmp = dishId.split('_')
        let sectionId = tmp[0] + '_' + tmp[1]
        let index = state.data[sectionId].dishes.indexOf(dishId)
        if (index > -1) {
            state.data[sectionId].dishes.splice(index, 1)
        }

        // Delete dish property from object
        Vue.delete(state.data, dishId)
        state.edited = true
    },
    createDish(state, dish) {
        let str = state.data[dish.sectionId].dishes.at(-1)

        let index
        if (typeof str === 'undefined') { 
            index = 0 
        }
        else { 
            index = parseInt(str.split('_').at(-1)) + 1 
        }

        let sectionId = dish.sectionId

        let key = sectionId + '_dish_' + index

        state.data[dish.sectionId].dishes.push(key)

        Vue.set(state.data, key, {
            cost: 0,
            disabled: true,
            favorite: false,
            name: dish.name,
            price: 0
        })

        state.edited = true
    },
    deleteSection(state, sectionId) {
        if (state.data.sections.length <= 1) { return }

        // Delete section from sections array
        let index = state.data.sections.indexOf(sectionId)
        if (index > -1) {
            state.data.sections.splice(index, 1)
        }

        // Delete section property from object
        Vue.delete(state.data, sectionId)
        Vue.delete(state.data.avg_order, sectionId)
        state.edited = true
    },
    updateSectionName(state, data) {
        state.data[data.sectionId].name = data.name
        state.edited = true
    },
    createSection(state, name) {

        let tmp = state.data.sections.at(-1);
        let index = parseInt(tmp.split('_')[1]) + 1
        let sectionId = 'section_' + index

        state.data.sections.push(sectionId)

        Vue.set(state.data, sectionId, {
            disabled: true,
            name: name,
            dishes: []
        })

        Vue.set(state.data.avg_order, sectionId, 0)

        state.edited = true
    },
    setType(state, type) {
        state.data.tipo = type
        state.edited = true
    },
    setName(state, name) {
        state.data.name = name
        state.edited = true
    },
    updateSectionAvgOrder(state, data) {
        state.data.avg_order[data.sectionId] = data.quantity
        state.edited = true
    },
    setSectionStatus(state, data) {
        state.data[data.id].disabled = data.disabled
    },
    setMenuStatus(state, disabled) {
        state.data.disabled = disabled
    },
    setEdited(state, bool) {
        state.edited = bool
    },
    setGiorniApertura(state, days) {
        state.data.giorni_apertura = days;
    },
    setNumeroCopertiGiornalieriMedio(state, value) {
        state.data.numero_coperti_giornalieri_medio = value;
        console.log(state);
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheButton from "~/components/general/TheButton.vue";

export default {
  name: "ExitPopup",
  components: {
    TheButton,
  },
  methods: {
    _save() {
      this.$store.dispatch("exitPopup/resolvePromise", true);
    },
    _exit() {
      this.$store.dispatch("exitPopup/resolvePromise", false);
    },
    _cancel() {
      this.$store.dispatch("exitPopup/resolvePromise", null);
    },
  },
};

import denormalizeRestaurant from '~/utilities/denormalizeRestaurant'
import storage from '~/assets/js/Storage'

export default {
    async save({ state, commit }) {
        let response = await this.$axios.post(this.$config.apiUrl + this.$config.endpoints.restaurant.save,
            denormalizeRestaurant(state.data), 
            { headers: { 'Authorization': 'Bearer ' + this.$localStore.get('accessToken') } })

        if (response.data == null) { // success
            storage.putRestaurant(state.data)
            commit('setEdited', false)
            
            // delete old analisys from storage
            storage.deleteAnalysisByRestaurant(state.data.restaurant_id)
        }
        else { //fail
            console.log(response)
        }
    },

    delete({ state, commit }) {
        this.$axios.delete(this.$config.apiUrl + this.$config.endpoints.restaurant.delete + '/' + state.data.restaurant_id,
            { headers: { 'Authorization': 'Bearer ' + this.$localStore.get('accessToken') } }
        )
        .then((response) => {
            // delete restaurant from local storage
            storage.deleteRestaurant(state.data.restaurant_id)

            let restaurants = storage.getRestaurantList()

            // change active restaurant from cookies
            let activeRestaurantId = restaurants[0].id
            this.$localStore.set('activeRestaurantId', activeRestaurantId)

            // reload store
            let restaurantData = storage.getRestaurant(activeRestaurantId)
            commit('restaurant/init', restaurantData, { root: true })

            // update navbar restaurants
            commit('pianificaNavbar/setRestaurants', restaurants, { root: true })

            // close popup
            commit('confirmationPopup/close', null, { root: true })
        })
        .catch((err) => {
            console.log(err)
            // Todo: display error or something
        })
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NewsEditPanel',
    data() {
        return {
            news: [],
            showErrors: false
        }
    },
    created() {
        this.$parent.save = this.save;
        
        if (this.$store.state.partner.molteplici_novita) {
            this.$store.state.partner.molteplici_novita.forEach(news => {
                const item = {
                    titolo: news.titolo,
                    descrizione: news.descrizione
                };
                this.news.push(item);
            });
        }
    },
    methods: {

        add() {
            this.news.push({ titolo: null, descrizione: null });
        },

        remove(index) {
            this.news.splice(index, 1);
        },

        async save() {
            if (!this.validateInput()) { return; }
            // Save on vuex
            this.$store.commit('partner/set', { molteplici_novita: this.news });
            // Dispatch vuex save
            await this.$store.dispatch('partner/saveNews');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            for (const item of this.news) {
                // validate title
                if (!item.titolo || item.titolo === '' ) {
                    this.showErrors = true;
                    return false;
                }
                // validate description
                if (!item.descrizione || item.descrizione === '' ) {
                    this.showErrors = true;
                    return false;
                }
            }
            return true;
        }

    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {v4 as uuidv4} from 'uuid'

export default {
    name: 'EventsEditPanel',
    data() {
        return {
            events: [],
            highlight: false
        }
    },
    created() {
        this.$parent.save = this.save;

        if (this.$store.state.partner.eventi) {
            this.$store.state.partner.eventi.forEach(event => {
                const item = {
                    titolo: event.titolo,
                    data_inizio: event.data_inizio,
                    data_fine: event.data_fine,
                    descrizione: event.descrizione,
                    banner: event.banner,
                    posizione: {
                        citta: event.posizione.citta,
                        provincia: event.posizione.provincia,
                        indirizzo: event.posizione.indirizzo,
                        codice_postale: event.posizione.codice_postale
                    },
                    open: false,
                    uploadError: false
                };
                this.events.push(item);
            });
        }
    },
    methods: {
        async getFileObject(e, index){
            this.loading = true;
            this.events[index].uploadError = false;

            try {
                await Promise.all([...e.target.files].map(async (file) => {
                    let formData = new FormData();
                    formData.append('file', file);
                    const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/${uuidv4()}`, formData, { 
                        headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
                    });
                    this.events[index].banner = data.file_name;
                }));

                this.$refs['imageInput'+index].value = "";
            }
            catch (error) {
                this.events[index].uploadError = true;
                console.log(error);
            }

            this.loading = false;
        },

        add() {
            for (const k in this.events) {
                this.events[k].open = false;
            }
            
            this.events.push({
                titolo: 'Nuovo evento',
                data_inizio: null,
                data_fine: null,
                descrizione: null,
                banner: null,
                posizione: {
                    citta: null,
                    provincia: null,
                    indirizzo: null,
                    codice_postale: null
                },
                open: true,
                uploadError: false
            });
        },

        remove(index) {
            this.events.splice(index, 1);
        },

        toggle(i) {
            if (!this.events[i].open) {
                for (const k in this.events) {
                    this.events[k].open = false;
                }
                this.events[i].open = true;
            }
            else {
                this.events[i].open = false;
            }
        },

        async save() {
            if (!this.validateInput()) { return; }

            // Save on vuex
            this.$store.commit('partner/setEvents', this.events);

            // Dispatch vuex save
            await this.$store.dispatch('partner/saveEvents');

            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            for (const event of this.events) {
                if (!event.titolo || event.titolo == '' ||
                    !event.data_inizio || event.data_inizio == '' ||
                    !event.data_fine || event.data_fine == '' ||
                    !event.posizione.citta || event.posizione.citta == '' ||
                    !event.descrizione || event.descrizione == ''
                ) { 
                    this.highlight = true;
                    return false;
                }
            }
            return true;
        }
    }
}

import {v4 as uuidv4} from 'uuid'

export default {

    // get data from server
    // - save data in local storage
    // - load vuex
    async fetch({ commit }) {
        try {
            const {data} = await this.$axios.get(`${this.$config.partners.apiUrl}/partners/get_private_data`, {
                headers: { 
                    'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken'),
                    'Content-Type': 'application/json'
                }
            });
            console.log(data);

            // set default values for arrays
            if (!data.eventi) { data.eventi = []; }
            if (!data.gallery) { data.gallery = []; }
            if (!data.categorie) { data.categorie = []; }
            if (!data.certificazioni) { data.certificazioni = []; }
            if (!data.team) { data.team = { membri: [] }; }
            if (!data.team.membri) { data.team.membri = []; }

            this.$localStore.set('pData', data);
            commit('set', data);
            return data;
        } catch (error) { 
            console.dir(error.response.status);
            if (error.response.status == 404) {
                // redirect to setup if partner data not found
                this.$router.push('/partners/me/setup');
                return false;
            }
        }
    },

    // get data from localstorage
    // - if empty > get data from server
    // - load vuex
    async retrive({ commit, dispatch }) {
        const data = this.$localStore.get('pData');
        if (!data) {
            return await dispatch('fetch');
        }
        commit('set', data);
        return true;
    },

    // send data to the server
    // - save on localstorage
    async save({ state, commit }) {
        commit('set', { tipologia: this.$localStore.get('pType') })
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/create`, state, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    // send events
    // - save on localstorage
    async savePartial({ state }, fields) {
        let obj = {};

        for (const field of fields) {
            obj[field] = state[field];
        }

        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, obj, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveMain({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                nome: state.nome,
                partita_iva: state.partita_iva,
                logo: state.logo,
                copertina: state.copertina
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveCategories({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                categorie: state.categorie
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveContacts({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                contatti: state.contatti,
                posizione: state.posizione,
                sito: state.sito
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveNews({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                molteplici_novita: state.molteplici_novita
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveServedAreas({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                zone_servite: state.zone_servite
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveGallery({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                gallery: state.gallery,
                descrizione: state.descrizione
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveCertifications({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                certificazioni: state.certificazioni
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveEvents({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                eventi: state.eventi
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveTeam({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                team: state.team
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveTraining({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                formazione: state.formazione
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveSkills({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                competenze: state.competenze
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    async saveExperiences({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                esperienze: state.esperienze
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },


    async saveReferent({ state }) {
        try {
            const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/partners/update`, {
                referente: state.referente
            }, { 
                headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
            });
            this.$localStore.set('pData', state);
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    // Public api
    async setPublicData({ commit }, data) {
        commit('set', data);
    }
}

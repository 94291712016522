//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {v4 as uuidv4} from 'uuid'

export default {
    name: "MainEditPanel",
    data() {
        return {
            showErrors: false,
            companyName: null,
            piva: null,
            logo: null,
            cover: null,
            errors: {
                logo: false,
                cover: false
            }
        }
    },
    created() {
        this.$parent.save = this.save;

        this.companyName = this.$store.state.partner.nome;
        this.piva = this.$store.state.partner.partita_iva;
        this.logo = this.$store.state.partner.logo;
        this.cover = this.$store.state.partner.copertina;
    },
    methods: {

        async save() {
            if (!this.validateInput()) { return; }
            // Save on vuex
            this.$store.commit('partner/set', { 
                partita_iva: this.piva,
                nome: this.companyName,
                logo: this.logo,
                copertina: this.cover
            });
            // Dispatch vuex save
            await this.$store.dispatch('partner/saveMain');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            if (!this.companyName || this.companyName == '') {
                this.showErrors = true;
                return false;
            }
            if (!this.piva || this.piva == '') {
                this.showErrors = true;
                return false;
            }
            if (!this.logo || this.logo == '') {
                this.showErrors = true;
                return false;
            }
            if (!this.cover || this.cover == '') {
                this.showErrors = true;
                return false;
            }
            return true;
        },

        async getFileObject(e, key){
            this.files[key] = e.target.files[0];
            const url = URL.createObjectURL(this.files[key]);
            this[key] = url;
        },

        async uploadImage(e, key){
            this.loading = true;
            this.errors[key] = false;

            let formData = new FormData();

            formData.append('file', e.target.files[0]);

            try {
                const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/${uuidv4()}`, formData, { 
                    headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
                });
                this[key] = data.file_name;
                this.loading = false;
            }
            catch (error) {
                console.log(error);
                this.errors[key] = true;
            }
        }

    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SkillsEditPanel',
    data() {
        return {
            items: [],
            highlight: false
        }
    },
    created() {
        this.$parent.save = this.save;

        const items = this.$store.state.partner.competenze;
        if (items) {
            for (const item of items) {
                this.items.push({
                    name: item.nome,
                    description: item.descrizione,
                    open: false
                });
            }
        }
    },
    computed: {
        competenze() {
            let arr = [];
            for (const item of this.items) {
                arr.push({
                    nome: item.name,
                    descrizione: item.description
                });
            }
            return arr;
        }
    },
    methods: {
        toggle(i) {
            if (!this.items[i].open) {
                for (const k in this.items) {
                    this.items[k].open = false;
                }
                this.items[i].open = true;
            }
            else {
                this.items[i].open = false;
            }
        },

        add() {
            for (const k in this.items) {
                this.items[k].open = false;
            }

            this.items.push({
                institution: null,
                title: 'Nuova qualifica',
                description: null,
                startDate: null,
                endDate: null,
                open: true
            });
        },

        remove(index) {
            this.items.splice(index, 1);
        },

        async save() {
            if (!this.validateInput()) { return; }
            this.$store.commit('partner/set', { competenze: this.competenze });
            await this.$store.dispatch('partner/saveSkills');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            for (const item of this.items) {
                if (!item.name || item.name == '') { 
                    this.highlight = true;
                    return false;
                }
            }
            return true;
        },
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from './components/TheHeader.vue'
import MobileHeader from './components/MobileHeader.vue'
import DarkFooter from './components/DarkFooter.vue'
import AuthenticationPopup from './components/AuthenticationPopup.vue'

export default {
    name: 'DefaultLayout',
    middleware: 'authenticated',
    components: {
        TheHeader,
        MobileHeader,
        DarkFooter,
        AuthenticationPopup
    },
    computed: {
        username() {
            return this.$localStore.get('username')
        },
        email() {
            return this.$localStore.get('email')
        },
        path() {
            return this.$nuxt.$route.name
        } 
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {v4 as uuidv4} from 'uuid'

export default {
    name: 'TeamEditPanel',
    data() {
        return {
            items: [],
            highlight: false
        }
    },
    created() {
        this.$parent.save = this.save;

        const team = this.$store.state.partner.team;
        if (!team || !team.membri) {
            return;
        }
        for (const item of team.membri) {
            this.items.push({
                firstname: item.nome,
                lastname: item.cognome,
                role: item.ruolo,
                linkedin: item.link_profilo,
                description: item.descrizione,
                email: item.email,
                phone: item.telefono,
                image: item.immagine,
                open: false,
                uploadError: false
            });
        }
    },
    computed: {
        membri() {
            let arr = [];
            for (const item of this.items) {
                arr.push({
                    nome: item.firstname,
                    cognome: item.lastname,
                    ruolo: item.role,
                    link_profilo: item.linkedin,
                    descrizione: item.description,
                    email: item.email,
                    telefono: item.phone,
                    immagine: item.image,
                    uploadError: false
                });
            }
            return arr;
        }
    },
    methods: {
        async getFileObject(e, index){
            this.loading = true;
            this.items[index].uploadError = false;

            try {
                await Promise.all([...e.target.files].map(async (file) => {
                    let formData = new FormData();
                    formData.append('file', file);
                    const {data} = await this.$axios.post(`${this.$config.partners.apiUrl}/images/file/${uuidv4()}`, formData, { 
                        headers: { 'Authorization': 'Bearer ' + this.$localStore.get('pAccessToken') }
                    });
                    this.items[index].image = data.file_name;
                }));

                this.$refs['imageInput'+index].value = "";   
            }
            catch (error) {
                console.log(error);
                this.items[index].uploadError = true;
            }

            this.loading = false;
        },

        add() {
            for (const k in this.items) {
                this.items[k].open = false;
            }

            this.items.push({
                firstname: 'Nuovo membro',
                lastname: null,
                role: null,
                linkedin: null,
                description: null,
                email: null,
                phone: null,
                image: null,
                open: true
            });
        },

        remove(index) {
            this.items.splice(index, 1);
        },

        toggle(i) {
            if (!this.items[i].open) {
                for (const k in this.items) {
                    this.items[k].open = false;
                }
                this.items[i].open = true;
            }
            else {
                this.items[i].open = false;
            }
        },

        async save() {
            if (!this.validateInput()) { return; }

            this.$store.commit('partner/set', { team: { membri: this.membri } });
            await this.$store.dispatch('partner/saveTeam');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() {
            for (const item of this.items) {
                if (!item.firstname || item.firstname == '' ||
                    !item.lastname || item.lastname == '' ||
                    !item.role || item.role == '' ||
                    !item.description || item.description == ''
                ) { 
                    this.highlight = true;
                    return false;
                }
            }
            return true;
        }
    }
}

import store from "~/assets/js/Storage";

export default {
  async retrieve({ commit, dispatch }) {
    const data = await dispatch("get");

    if (data) {
      console.log(data);
      commit("init", data);
    }
  },

  async get() {
    const url = `${this.$config.apiUrl}/planner/get`;

    const headers = {
      headers: {
        Authorization: "Bearer " + this.$localStore.get("accessToken"),
      },
    };

    try {
      const res = await this.$axios.get(url, headers);
      store.storePlanner(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },

  async save({ state }) {
    const url = `${this.$config.apiUrl}/planner/save`;

    const headers = {
      headers: {
        Authorization: "Bearer " + this.$localStore.get("accessToken"),
      },
    };

    try {
      const res = await this.$axios.post(url, state, headers);
      store.storePlanner(state);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from "~/assets/js/User";
import storage from "~/assets/js/Storage";

export default {
  name: "RistoratoriTopHeader",
  props: {
    withSidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    username() {
      return this.$localStore.get("name")
        ? this.$localStore.get("name")
        : this.$localStore.get("username");
    },
  },
  data() {
    return {
      userMenuOpen: false,
      authenticated: storage.isAuthenticated(),
    };
  },
  methods: {
    signOut() {
      this.$localStore.clearAll();
      this.$cookies.remove("authenticated");
      User.signOut();
      window.location.href = "/";
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
import TheButton from "../../components/general/TheButton.vue";
import storage from "~/assets/js/Storage";
import introJs from "intro.js/intro.js";
import "intro.js/introjs.css";
import User from "~/assets/js/User";
import SideBanner from "../../components/ad-banners/SideBanner.vue";

export default {
  name: "PianificaSidebar",
  components: {
    TheButton,
    SideBanner,
  },
  computed: {
    markerTopPercentage() {
      let activePage = this.$store.state.pianificaSidebar.activePage;
      let activeSection = this.$store.state.pianificaSidebar.activeSection;
      let position =
        this.$store.state.pianificaSidebar[activePage].order.indexOf(
          activeSection
        );
      let sectionCount =
        Object.keys(this.$store.state.pianificaSidebar[activePage].sections)
          .length - 1;
      let percentage = (position * 100) / sectionCount;
      return percentage;
    },
  },
  mounted() {
    if (this.$localStore.get("showTutorial")) {
      setTimeout(() => {
        this.startTutorial();
        User.updateUserAttribute("custom:show_tutorial", "1").then(() =>
          this.$localStore.set("showTutorial", false)
        );
      }, 3000);
    }
  },
  methods: {
    ...mapMutations({
      setActiveSection: "pianificaSidebar/setActiveSection",
      initAnalysis: "analysis/init",
      initRestaurant: "restaurant/init",
      initMenu: "menu/init",
      setMenuEdited: "menu/setEdited",
      setRestaurantEdited: "restaurant/setEdited",
    }),
    startTutorial() {
      introJs()
        .setOptions({
          tooltipClass: "customTooltip",
          nextLabel: "Avanti",
          prevLabel: "Indietro",
          doneLabel: "Fatto",
          steps: [
            {
              element: document.querySelector('[data-step="1"]'),
              intro:
                "Usa la barra di navigazione per muoverti tra le sezioni del Pianifica",
              position: "right",
              title: "1",
            },
            {
              element: document.querySelector('[data-step="2"]'),
              intro:
                "Ricordati sempre di salvare le modifiche effettuate sul Pianifica",
              position: "bottom",
              title: "2",
            },
            {
              element: document.querySelector('[data-step="3"]'),
              intro:
                "Scegli la combinazione di Locale e Menù che vuoi analizzare",
              position: "bottom",
              title: "3",
            },
            {
              element: document.querySelector('[data-step="4"]'),
              intro:
                'Clicca "Analizza" e guarda l\'analisi del tuo piano di Business',
              position: "bottom",
              title: "4",
            },
            {
              title: "Fatto",
              intro: "Buon lavoro!<br>Dal Team di CreaRistorante.it",
            },
          ],
        })
        .start();
    },
    saveMenu() {
      this.$store.dispatch("menu/save");
    },
    saveRestaurant() {
      this.$store.dispatch("restaurant/save");
    },
    async requestAnalysis() {
      if (!storage.getMenuList().length) {
        this.$router.push({ path: "/pianifica/menu/nuovo" });
        return;
      }

      switch (this.$route.name) {
        case "pianifica-locale":
          if (this.$store.state.restaurant.edited) {
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // stay on current page
            if (confirmed === null) {
              return;
            }

            // save and change page
            if (confirmed) {
              await this.$store.dispatch("restaurant/save");
            } else {
              // delete changes
              let restaurantData = storage.getRestaurant(
                this.$store.state.restaurant.data.restaurant_id
              );
              this.initRestaurant(restaurantData);
              this.setRestaurantEdited(false);
            }
          }
          break;
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            let confirmed = await this.$store.dispatch(
              "exitPopup/getConfirmation"
            );

            // stay on current page
            if (confirmed === null) {
              return;
            }

            // save and change page
            if (confirmed) {
              await this.$store.dispatch("menu/save");
            } else {
              // delete changes
              let menuData = storage.getMenu(
                this.$store.state.menu.data.menu_id
              );
              this.initMenu(menuData);
              this.setMenuEdited(false);
            }
          }
          break;
      }

      // empty analysis if menu is disabled
      if (this.$store.state.menu.data.disabled) {
        this.initAnalysis(null);
        this.$router.push("/pianifica/analisi");
      } else {
        // if analysis is in storage
        let analysis = storage.getAnalysis(
          this.$localStore.get("activeRestaurantId"),
          this.$localStore.get("activeMenuId")
        );

        if (analysis) {
          this.initAnalysis(analysis);
          this.$router.push("/pianifica/analisi");
        } else {
          // request analisys
          this.$store.dispatch("analysis/request");
        }
      }
    },
  },
};

import Vue from "vue";

export default {
    // Categories
    pushCategory(state, categoryId) {
        state.categories.push(categoryId);
    },
    spliceCategory(state, categoryId) {
        const i = state.categories.indexOf(categoryId);
        if (i > -1) { state.categories.splice(i, 1); }
    },
    emptyCategories(state) {
        state.categories.splice(0);
    },

    // Sidebar
    openSidebar(state) {
        state.sidebar.isOpen = true;
    },
    closeSidebar(state) {
        state.sidebar.isOpen = false;
    },

    // Pagination
    setPageIndex(state, pageIndex) {
        state.pagination.pageIndex = pageIndex;
    },
    storePage(state, { page, index }) {
        if (!state.pages[index]) {
            Vue.set(state.pages, index, []);
        }
        else {
            state.pages[index].splice(0);
        }
        
        for (const item of page) {
            state.pages[index].push(item);
        }
    },
    setTotalPages(state, totalPages) {
        state.pagination.totalPages = totalPages;
    },

    // Loader
    enableLoader(state) {
        state.loading = true;
    },
    disableLoader(state) {
        state.loading = false;
    }
};

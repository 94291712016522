//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopHeader from "../components/TopHeader.vue";
import LeftSidebar from "./components/LeftSidebar.vue";
import ExitPopup from "~/components/ristoratori/ExitPopup";
import { mapMutations } from "vuex";
import storage from "~/assets/js/Storage";
import MobileTopHeader from "../components/MobileTopHeader.vue";
import MobileSidebar from "./components/MobileSidebar.vue";

export default {
  name: "RistoratoriPlannerWithSidebarLayout",
  components: {
    TopHeader,
    LeftSidebar,
    ExitPopup,
    MobileTopHeader,
    MobileSidebar
  },
  data() {
    return {
      mobileIsOpen: false,
    };
  },
  beforeMount() {
    addEventListener("error", this.$logger);

    this.setActivePage(this.$route.path);

    // load menu store if not already loaded
    if (!this.$store.state.menu.data) {
      let activeMenuId = this.$localStore.get("activeMenuId");
      let menuData = storage.getMenu(activeMenuId);
      this.initMenu(menuData);
    }

    window.onbeforeunload = (e) => {
      switch (this.$route.name) {
        case "pianifica-menu":
          if (this.$store.state.menu.edited) {
            e = e || window.event;
            // For IE and Firefox prior to version 4
            if (e) {
              e.returnValue = "Sure?";
            }
            // For Safari
            return "Sure?";
          }
          break;
      }
    };
  },
  beforeDestroy() {
    removeEventListener("error", this.$logger);
  },
  methods: {
    setMobileOpen(value) {
      this.mobileIsOpen = value;
    },
    ...mapMutations({
      initMenu: "menu/init",
      setActivePage: "pianificaSidebar/setActivePage",
    }),
  },
};

//
//
//
//
//
//
//
//
//
//

import TheHeader from './components/TheHeader.vue';
import MobileHeader from './components/MobileHeader.vue';

export default {
    name: 'DefaultLayout',
    middleware: 'alreadyAuthenticated',
    components: {
        TheHeader,
        MobileHeader
    }
}

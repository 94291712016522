//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopHeader from "../components/TopHeader.vue";
import MobileTopHeader from "../components/MobileTopHeader.vue";
import MobileProgress from "./components/MobileProgress.vue";
import LeftSidebar from "./components/LeftSidebar.vue";
import ExitPopup from "~/components/ristoratori/ExitPopup";

export default {
  name: "RistoratoriPlannerWithSidebarLayout",
  components: {
    TopHeader,
    LeftSidebar,
    ExitPopup,
    MobileTopHeader,
    MobileProgress,
  },
  data() {
    return {
      mobileIsOpen: false,
    };
  },
  methods: {
    setMobileOpen(value) {
      this.mobileIsOpen = value;
    },
  },
  beforeCreate() {
    // Retrieve planner state from local storage
    this.$store.dispatch("planner/retrieve");
  },
};

export default ({ $axios, $config, $localStore }, inject) => {
    inject('logger', async (e) => {
        console.log('Sending error report: ', e);

        const response = await $axios.post(
            `${$config.apiUrl}/exception/store_exception`,
            { 
                error: JSON.stringify(e),
                email: $localStore.get('email'),
                activeMenuId: $localStore.get('activeMenuId'),
                activeRestaurantId: $localStore.get('activeRestaurantId'),
                route: window.location.href
            },
            { headers: { 'Authorization': 'Bearer ' + $localStore.get('accessToken') } }
        );

        console.log(response);
    })
}

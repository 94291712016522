//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegularHeader from '../components/TheHeader.vue'
import PartnerHeader from '../components/partners/TheHeader.vue'
import RegularMobileHeader from '../components/MobileHeader.vue'
import PartnerMobileHeader from '../components/partners/MobileHeader.vue'
import AuthenticationPopup from '../components/AuthenticationPopup.vue'
import SupportWidget from '../../components/support/SupportWidget.vue'

export default {
    name: 'PartnersCleanFullWidthNoFooter.vue',
    components: {
        RegularHeader,
        PartnerHeader,
        RegularMobileHeader,
        PartnerMobileHeader,
        AuthenticationPopup,
        SupportWidget
    },
    mounted() {
        console.log(this.$localStore.get('pAuthenticated'));
    }
}

export default {
    async fetchPage({ state, commit }) {
        // Base url
        let url = `${this.$config.partners.publicApiUrl}/partners/getall?size=${this.$config.partners.search.pageSize}`;
        
        // Set categories
        if (state.categories.length) {
            url += `&categorie=${state.categories.join(',')}`;
        }

        // Set last_evaluated_key
        if (state.pagination.pageIndex > 0) {
            const prevPage = state.pages[state.pagination.pageIndex - 1];
            const lastEvaluatedKey = prevPage[prevPage.length - 1].collaboratore_id;
            url += `&last_evaluated_key=${lastEvaluatedKey}`;
        }
        
        commit('enableLoader');

        try {
            const response = await this.$axios.get(url);
            console.log(response);

            commit('storePage', { 
                index: state.pagination.pageIndex,
                page: response.data.collaboratori
            });

            if (response.data.last_evaluated_key === null) {
                commit('setTotalPages', state.pagination.pageIndex + 1);
            } else {
                commit('setTotalPages', null);
            }
        }
        catch (error) {
            console.log(error);
        }

        commit('disableLoader');
    },

    init({ dispatch, commit }) {
        commit('setPageIndex', 0);
        dispatch('fetchPage');
    },

    nextPage({ state, commit, dispatch }) {
        commit('setPageIndex', state.pagination.pageIndex + 1);
        dispatch('fetchPage');
    },

    async prevPage({ state, commit, dispatch }) {
        commit('enableLoader');
        await new Promise(r => setTimeout(r, 300));
        commit('setPageIndex', state.pagination.pageIndex - 1);
        commit('disableLoader');
        //dispatch('fetchPage');
    }
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cats from '~/utilities/partners/categories'

export default {
    name: 'PartnersSearchSidebar',

    data() {
        return {
            isSticky: true,
            categories: []
        }
    },

    computed: {
        activeCategories() { return this.$store.state.search.categories; },
        isOpen() { return this.$store.state.search.sidebar.isOpen; }
    },

    beforeMount () {
        window.addEventListener('scroll', this.handleScroll);
        for (const key in cats) { this.categories.push(cats[key]); }
    },

    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    mounted() {
        this.handleScroll();
    },

    methods: {
        showAllCategories() {
            this.$store.commit('search/emptyCategories');
            this.$store.dispatch('search/init');
            window.scrollTo(0, 0);
        },
        
        handleScroll () {
            if (window.innerWidth < 1024) { return; }
            const stickyHeight = this.$refs.sticky.offsetHeight;
            const wrpEndfromTop = this.$refs.sidebar.getBoundingClientRect().bottom;
            this.isSticky = wrpEndfromTop > (stickyHeight + 75 + 40);
        },

        toggleCategory(categoryId) {
            if (this.activeCategories.includes(categoryId)) {
                this.$store.commit('search/spliceCategory', categoryId);
            }
            else {
                this.$store.commit('search/pushCategory', categoryId);
            }
            this.$store.dispatch('search/init');
            window.scrollTo(0, 0);
        },

        openSidebar() { this.$store.commit('search/closeSidebar'); }
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative shadow bg-white z-20",attrs:{"id":"pianifica-sidebar"}},[_c('div',{staticClass:"py-10 fixed custom-size flex flex-col justify-between",staticStyle:{"height":"calc(100% - 75px)"}},[_c('div',{staticClass:"top",attrs:{"data-step":"1"}},[_vm._m(0),_vm._v(" "),_c('nuxt-link',{class:("trasitiong-bg px-5 text-lg font-bold color-purple mt-4 block " + (_vm.$store.state.pianificaSidebar.activePage == 'menuAnalysis'
            ? 'active-page-bg'
            : '')),attrs:{"to":"/analisi-menu"}},[_vm._v(_vm._s(_vm.$store.state.pianificaSidebar.menuAnalysis.label))]),_vm._v(" "),_c('Transition',{attrs:{"name":"sections"}},[(_vm.$store.state.pianificaSidebar.activePage == 'menuAnalysis')?_c('div',{staticClass:"px-5 flex"},[_c('div',{staticClass:"section-marker"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"marker",style:(("top: " + _vm.markerTopPercentage + "%;"))},[_c('div',{staticClass:"inner"})])])]),_vm._v(" "),_c('div',{staticClass:"grow"},_vm._l((_vm.$store.state.pianificaSidebar
                .menuAnalysis.sections),function(section,key){return _c('div',{key:key,staticClass:"py-1"},[_c('p',{class:("text-base m-0 cursor-pointer custom-transition " + (key == _vm.$store.state.pianificaSidebar.activeSection
                    ? 'opacity-100 text-black'
                    : 'opacity-70')),on:{"click":function($event){return _vm.$scrollToId(key)}}},[_vm._v("\n                "+_vm._s(section.label)+"\n              ")])])}),0)]):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"name":"sections"}},[(_vm.$store.state.pianificaSidebar.activePage == 'partialAnalysis')?_c('div',[_c('nuxt-link',{class:("trasitiong-bg px-5 text-lg font-bold color-purple mt-4 block " + (_vm.$store.state.pianificaSidebar.activePage == 'partialAnalysis'
                ? 'active-page-bg'
                : '')),attrs:{"to":"/pianifica/analisi"}},[_vm._v(_vm._s(_vm.$store.state.pianificaSidebar.analysis.label))]),_vm._v(" "),_c('div',{staticClass:"px-5 flex"},[_c('div',{staticClass:"section-marker"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"marker",style:(("top: " + _vm.markerTopPercentage + "%;"))},[_c('div',{staticClass:"inner"})])])]),_vm._v(" "),_c('div',{staticClass:"grow"},_vm._l((_vm.$store.state.pianificaSidebar
                  .partialAnalysis.sections),function(section,key){return _c('div',{key:key,staticClass:"py-1"},[_c('p',{class:("text-base m-0 cursor-pointer custom-transition " + (key == _vm.$store.state.pianificaSidebar.activeSection
                      ? 'opacity-100 text-black'
                      : 'opacity-70')),on:{"click":function($event){return _vm.$scrollToId(key)}}},[_vm._v("\n                  "+_vm._s(section.label)+"\n                ")])])}),0)])],1):_vm._e()]),_vm._v(" "),(_vm.$store.state.adBanners.analysis.showSideBanner)?_c('div',{staticClass:"px-5 py-6"},[_c('SideBanner')],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"bottom px-5"},[_c('div',{staticClass:"mb-3",attrs:{"data-step":"2"}},[_c('div',{staticClass:"mt-4"},[_c('TheButton',{class:("border-2 transition-opacity w-full text-center " + (!_vm.$store.state.menu.edited ? 'opacity-40' : '')),attrs:{"href":"javascript:void(0)","green":"","outline":""},nativeOn:{"click":function($event){return _vm.saveMenu()}}},[_c('span',{staticClass:"text-sm"},[_c('i',{staticClass:"fa-solid fa-floppy-disk mr-2 text-xl align-bottom"}),_vm._v("Salva i cambiamenti")])])],1)]),_vm._v(" "),_c('TheButton',{staticClass:"min-w-custom text-center w-full",attrs:{"green":""},nativeOn:{"click":function($event){return _vm.requestPartialAnalysis()}}},[_vm._v("Analizza")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center mb-4"},[_c('p',{staticClass:"text-xl font-bold select-none px-5"},[_vm._v("Analisi Menu")])])}]

export { render, staticRenderFns }
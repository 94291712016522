//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import TheMenu from '../../components/partners/partials/TheMenu.vue'
	import TheButton from '../../../components/general/TheButton.vue'
	import User from '~/assets/js/User'

    export default {
        name: 'PartnerTheHeader',
        data() {
            return {
                accountMenuPopupOpen: false,
                scrolled: false
            }
        },
        props: {
            homepage: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            username() {
                return this.$localStore.get('pEmail');
            },
            homelink() {
                return this.$cookies.get('pAuthenticated') ? '/partners/me' : '/';
            }
		},
        components: {
            TheMenu,
            TheButton
        },
		methods: {
            signOut() {
                this.$localStore.clearAll();
                this.$cookies.remove('authenticated');
                this.$cookies.remove('pAuthenticated');
                User.signOut();
                window.location.href = 'https://www.crearistorante.it/partners';
            },
            handleScroll() {
                this.scrolled = window.scrollY > 200; 
            }
		},
        beforeMount () {
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy () {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

export const state = () => ({
    active: false,
    panelId: null
})
    
export const mutations = {
    open(state, panelId) {
        state.active = true;
        state.panelId = panelId;
    },
    close(state) {
        state.active = false;
        state.panelId = null;
    }
}
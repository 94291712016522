export const state = () => ({})
    
export const mutations = {}

/*
export const actions = {
    async nuxtServerInit({ commit },  app ) {
        commit('pianificaSidebar/setActivePage', app.route.path)
    }
}
*/
export const state = () => ({
    open: false,
    label: null,
    name: null,
    error: null,
    action: null
})
    
export const mutations = {
    toggle(state) {
        state.open = !state.open
    },
    close(state) {
        state.open = false
        state.label = null
        state.name = null
        state.error = null
        state.action = null
    },
    open(state, data) {
        if (data) {
            if (!data.label) { state.label = null }
            else { state.label = data.label }
            
            if (!data.name) { state.name = null }
            else { state.name = data.name }
            
            if (!data.action) { state.action = null }
            else { state.action = data.action }
        }
        state.error = null
        state.open = true
    },
    setError(state, errorText) {
        state.error = errorText
    },
    setName(state, name) {
        state.name = name
    }
}
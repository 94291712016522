export default { 
  homepageUrl: 'https://dev.crearistorante.it',
  googleRedirectLink: 'https://apriristorante.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&client_id=6ap1glpl39loduu8upaqkve1ii&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://servizi.crearistorante.it/private/google&identity_provider=Google', 
  apiUrl: 'https://8h7x372r6g.execute-api.eu-west-1.amazonaws.com/dev', 
  partners: { 
    apiUrl: 'https://efv27pw9bd.execute-api.eu-west-1.amazonaws.com/dev', 
    publicApiUrl: 'https://8h7x372r6g.execute-api.eu-west-1.amazonaws.com/dev' 
  },
  cognito: {
    baseWebsite: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_KHXfBhiO1', 
      clientId: 'bpvm5pv2ml8u7kls6mhu5a4vk'
    },
    partners: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_2hBl6EGcl',
      clientId: '5nm9k6361kfh209btsk27heo2v'
    }
  }
}

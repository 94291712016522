export default [
    {
        name: 'Abruzzo',
        provinces: ['Chieti', 'L\'Aquila', 'Pescara', 'Teramo']
    },
    {
        name: 'Basilicata',
        provinces: ['Matera', 'Potenza']
    },
    {
        name: 'Calabria',
        provinces: ['Catanzaro', 'Cosenza', 'Crotone', 'Reggio di Calabria', 'Vibo Valentia']
    },
    {
        name: 'Campania',
        provinces: ['Avellino', 'Benevento', 'Caserta', 'Napoli', 'Salerno']
    },
    {
        name: 'Emilia Romagna',
        provinces: ['Bologna', 'Ferrara', 'Forlì-Cesena', 'Modena', 'Parma', 'Piacenza', 'Ravenna', 'Reggio nell\'Emilia', 'Rimini']
    },
    {
        name: 'Friuli Venezia Giulia',
        provinces: ['Gorizia', 'Pordenone', 'Trieste', 'Udine']
    },
    {
        name: 'Lazio',
        provinces: ['Frosinone', 'Latina', 'Rieti', 'Roma', 'Viterbo']
    },
    {
        name: 'Liguria',
        provinces: ['Genova', 'Imperia', 'La Spezia', 'Savona']
    },
    {
        name: 'Lombardia',
        provinces: ['Bergamo', 'Brescia', 'Como', 'Cremona', 'Lecco', 'Lodi', 'Mantova', 'Milano', 'Monza e della Brianza', 'Pavia', 'Sondrio', 'Varese']
    },
    {
        name: 'Marche',
        provinces: ['Ancona', 'Ascoli Piceno', 'Fermo', 'Macerata', 'Pesaro e Urbino']
    },
    {
        name: 'Molise',
        provinces: ['Campobasso', 'Isernia']
    },
    {
        name: 'Piemonte',
        provinces: ['Alessandria', 'Asti', 'Biella', 'Cuneo', 'Novara', 'Torino', 'Verbano-Cusio-Ossola', 'Vercelli']
    },
    {
        name: 'Puglia',
        provinces: ['Bari', 'Barletta-Andria-Trani', 'Brindisi', 'Foggia', 'Lecce', 'Taranto']
    },
    {
        name: 'Sardegna',
        provinces: ['Cagliari', 'Carbonia-Iglesias', 'Medio Campidano', 'Nuoro', 'Ogliastra', 'Olbia-Tempio', 'Oristano', 'Sassari']
    },
    {
        name: 'Sicilia',
        provinces: ['Agrigento', 'Caltanissetta', 'Catania', 'Enna', 'Messina', 'Palermo', 'Ragusa', 'Siracusa', 'Trapani']
    },
    {
        name: 'Toscana',
        provinces: ['Firenze', 'Grosseto', 'Livorno', 'Lucca', 'Massa-Carrara', 'Pisa', 'Pistoia', 'Prato', 'Siena']
    },
    {
        name: 'Trentino-Alto Adige',
        provinces: ['Bolzano/Bozen', 'Trento']
    },
    {
        name: 'Umbria',
        provinces: ['Perugia', 'Terni']
    },
    {
        name: 'Valle d\'Aosta',
        provinces: ['Valle d\'Aosta/Vallée d\'Aoste']
    },
    {
        name: 'Veneto',
        provinces: ['Belluno', 'Padova', 'Rovigo', 'Treviso', 'Venezia', 'Verona', 'Vicenza']
    }
];
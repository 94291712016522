let riskData = {
    '-3': {
        title: 'Estremo',
        solution: [
            {
                text: 'Riduci le ore di apertura locale',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Aumenta la rotazione tavolo',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Aumenta i costi della tua azienda',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'costiCucina'
            },
            {
                text: 'Riduci l\'ordine tipico',
                path: 'ristoratori-pianificazione-guidata-step-due-menu',
                anchor: 'ordineTipico'
            } 
        ],
        class: 'color-darkred'
    },
    '3': {
        title: 'Estremo',
        solution: [
            {
                text: 'Aumenta le ore di apertura locale',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Riduci la rotazione tavolo',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Riduci i costi della tua azienda',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'costiCucina'
            },
            {
                text: 'Aumenta l\'ordine tipico',
                path: 'ristoratori-pianificazione-guidata-step-due-menu',
                anchor: 'ordineTipico'
            },
        ],
        class: 'color-darkred'
    },
    '-2': {
        title: 'Alto',
        solution: [
            {
                text: 'Riduci le ore di apertura locale',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Aumenta la rotazione tavolo',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Aumenta i costi della tua azienda',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'costiCucina'
            },
            {
                text: 'Riduci l\'ordine tipico',
                path: 'ristoratori-pianificazione-guidata-step-due-menu',
                anchor: 'ordineTipico'
            } 
        ],
        class: 'color-red'
    },
    '2': { 
        title: 'Alto',
        solution: [
            {
                text: 'Aumenta le ore di apertura locale',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Riduci la rotazione tavolo',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Riduci i costi della tua azienda',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'costiCucina'
            },
            {
                text: 'Aumenta l\'ordine tipico',
                path: 'ristoratori-pianificazione-guidata-step-due-menu',
                anchor: 'ordineTipico'
            },
        ],
        class: 'color-red'
    },
    '1': { 
        title: 'Medio',
        solution: [
            {
                text: 'Aumenta le ore di apertura locale',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Riduci la rotazione tavolo',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Riduci i costi della tua azienda',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'costiCucina'
            },
            {
                text: 'Aumenta l\'ordine tipico',
                path: 'ristoratori-pianificazione-guidata-step-due-menu',
                anchor: 'ordineTipico'
            },
        ],
        class: 'color-orange'
    },
    '-1': {
        title: 'Medio',
        solution: [
            {
                text: 'Riduci le ore di apertura locale',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Aumenta la rotazione tavolo',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'gestioneLocale'
            },
            {
                text: 'Aumenta i costi della tua azienda',
                path: 'ristoratori-pianificazione-guidata-step-due-locale',
                anchor: 'costiCucina'
            },
            {
                text: 'Riduci l\'ordine tipico',
                path: 'ristoratori-pianificazione-guidata-step-due-menu',
                anchor: 'ordineTipico'
            } 
        ],
        class: 'color-orange'
    },
    '0': {
        title: 'Moderato',
        solution: 'Hai limitato il rischio! Ma ricorda, effettuando scelte oculate e consapevoli potrai ridurre il rischio d\'impresa, ma non potrai mai annullarlo!',
        class: 'color-green'
    }
}

let sanityCheckData = {
    spese_generali: {
        title: 'Spese generali',
        problems: {
            '-2': 'Spesa insufficente',
            '-1': 'Spesa insufficente',
            '1': 'Spesa eccessiva',
            '2': 'Spesa eccessiva'
        },
        solutions: {
            '-2': 'Aumenta il costo delle', //composizione sale
            '-1': 'Aumenta il costo delle',
            '1': 'Diminuisci il costo delle',
            '2': 'Diminuisci il costo delle'
        },
        sections: [
            'affitto',
            'arredamento_cucina',
            'attrezzature',
            'arredamento_sala',
            'posateria_usa_getta',
            'bar_intrattenimento',
            'oneri_consumi',
            'costi_unatantum',
            'commercialista_marketing'
        ]
    },
    personale: {
        title: 'Personale',
        problems: {
            '-2': 'Spesa insufficente',
            '-1': 'Spesa insufficente',
            '1': 'Spesa eccessiva',
            '2': 'Spesa eccessiva'
        },
        solutions: {
            '-2': 'Aumenta il costo del', // cucina
            '-1': 'Aumenta il costo del',
            '1': 'Diminuisci il costo del',
            '2': 'Diminuisci il costo del'
        },
        sections: [
            'personale_cucina',
            'personale_sala'
        ]
    },
    materie_prime: {
        title: 'Materie prime',
        problems: {
            '-2': 'Spesa insufficente', 
            '-1': 'Spesa insufficente',
            '1': 'Spesa eccessiva',
            '2': 'Spesa eccessiva'
        },
        solutions: {
            '-2': 'Diminuisci il', //menu
            '-1': 'Diminuisci il',
            '1': 'Aumenta il',
            '2': 'Aumenta il'
        },
        sections: ['smart_menu']
    }
}

// caso 1 nuova grafica
// caso 2 nuova grafica
// caso 3

let render = {
    affitto: {
        title: 'Costi dell\'immobile',
        iconHtml: `<img src="${require('~/assets/img/icons/affitto.svg')}" class="mx-auto" width="26"/>`
    },
    arredamento_cucina: {
        title: 'Arredamento cucina',
        iconHtml: '<i class="fa-solid fa-fire-burner"></i>'
    },
    attrezzature: {
        title: 'Attrezzature',
        iconHtml: '<i class="fa-solid fa-kitchen-set"></i>'
    },
    arredamento_sala: {
        title: 'Arredamento sala',
        iconHtml: '<i class="fa-solid fa-table"></i>'
    },
    posateria_usa_getta: {
        title: 'Posateria usa getta',
        iconHtml: '<i class="fa-solid fa-utensils"></i>'
    },
    bar_intrattenimento: {
        title: 'Bar e intrattenimento',
        iconHtml: '<i class="fa-solid fa-champagne-glasses"></i>'
    },
    oneri_consumi: {
        title: 'Oneri e consumi',
        iconHtml: '<i class="fa-solid fa-plug"></i>'
    },
    costi_unatantum: {
        title: 'Costi una tantum',
        iconHtml: '<i class="fa-solid fa-hand-holding-dollar"></i>'
    },
    commercialista_marketing: {
        title: 'Commercialista e marketing',
        iconHtml: '<i class="fa-solid fa-calculator"></i>'
    },
    personale_cucina: {
        title: 'Personale cucina',
        iconHtml: `<img src="${require('~/assets/img/icons/personale_cucina.svg')}" class="mx-auto" width="20"/>`
    },
    personale_sala: {
        title: 'Personale sala',
        iconHtml: `<img src="${require('~/assets/img/icons/personale_sala.svg')}" class="mx-auto" width="20"/>`
    },
    smart_menu: {
        title: 'Menu',
        iconHtml: `<img src="${require('~/assets/img/icons/smart_menu.svg')}" class="mx-auto" width="20"/>`
    }
}

export default {
    getRiskTitle(state) {
        if (state.data.risk == null) { return null }
        return riskData[state.data.risk].title
    },
    getRiskColorClass(state) {
        if (state.data.risk == null) { return null }
        return riskData[state.data.risk].class
    },
    getRiskSolution(state) {
        if (state.data.risk == null) { return null }
        return riskData[state.data.risk].solution
    },
    getSanityCheckSections(state) {
        if (!state.data.sanity_check) { return null }
        let data = []
        for (const sectionId of sanityCheckData[state.data.sanity_check].sections) {
            data.push(render[sectionId])
        }
        return data
    },
    getSanityCheckProblem(state) {
        if (!state.data.sanity_check) { return null }
        return sanityCheckData[state.data.sanity_check].problems[state.data.sanity_check_magnitude]
    },
    getSanityCheckSolution(state) {
        if (!state.data.sanity_check) { return null }
        return sanityCheckData[state.data.sanity_check].solutions[state.data.sanity_check_magnitude]
    }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from '../components/TheHeader.vue'
import PartnerHeader from '../components/partners/TheHeader.vue'
import DarkFooter from '../components/DarkFooter.vue'
import AuthenticationPopup from '../components/AuthenticationPopup.vue'
import MobileHeader from '../components/partners/MobileHeader.vue'
import SearchSidebar from '../../layouts/components/partners/searchSidebar.vue'
import SupportWidget from '../../components/support/SupportWidget.vue'
import PartnerMobileHeader from '../components/partners/MobileHeader.vue'
import RegularMobileHeader from '../components/MobileHeader.vue'

export default {
    name: 'PartnersCleanFullWidth',
    components: {
        TheHeader,
        PartnerHeader,
        DarkFooter,
        AuthenticationPopup,
        MobileHeader,
        SearchSidebar,
        SupportWidget,
        RegularMobileHeader,
        PartnerMobileHeader
    },

    methods: {
        openSidebar() { this.$store.commit('search/openSidebar'); }
    }
}

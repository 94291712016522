export const state = () => ({
    items: [],
    lastId: null,
    lastPageIndex: 1,
    categories: [],
    completed: false,
    completedIndex: null
})
    
export const mutations = {
    setCompleted(state, bool) {
        state.completed = bool;
    },
    setCompletedIndex(state, int) {
        state.completedIndex = int;
    },

    setlastPageIndex(state, lastPageIndex) {
        state.lastPageIndex = lastPageIndex;
    },

    pushItem(state, item) {
        console.log(item.slug);
        state.items.push(item);
    },

    clear(state) {
        state.items.splice(0);
        state.lastId = null;
        state.lastPageIndex = 1;
        state.completed = false;
        state.completedIndex = null;
    },

    pushCategory(state, categoryId) {
        state.categories.push(categoryId);
    },

    removeCategory(state, categoryId) {
        const index = state.categories.indexOf(categoryId);
        if (index !== -1) {
            state.categories.splice(index, 1);
        }
    },

    clearCategories(state) {
        state.categories.splice(0);
    },
}

export const actions = {
    async fetchPage({ state, commit }, { pageIndex }) {
        if (state.items.length >= this.$config.partners.search.pageSize * pageIndex) { console.log('returned for no reason'); return; }

        let url = `${this.$config.partners.publicApiUrl}/partners/getall/size=${this.$config.partners.search.pageSize}`;

        if (state.categories.length) {
            url += `/categorie=${state.categories.join(',')}`;
        }

        if (state.items.length) {
            const lastItem = state.items[state.items.length-1];
            const lastFetchedId = lastItem.collaboratore_id;
            url += `?last_evaluated_key=${lastFetchedId}`;
        }
        

        this.$axios.get(url).then((response) => {
            console.log(response);
            if (!response.data.collaboratori.length) {
                commit('setCompleted', true);
                commit('setCompletedIndex', pageIndex);
            }
            else {
                response.data.collaboratori.forEach(item => {
                    commit('pushItem', item);
                });
            }

        }).catch((error) => {
            console.log(error);
        });
    },

    clearCache({ commit }) {
        commit('clear', 1);
    }
}
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from '../components/TheHeader.vue'
import MobileHeader from '../components/MobileHeader.vue'
import TheFooter from '../components/TheFooter.vue'
import AuthenticationPopup from '../components/AuthenticationPopup.vue'

export default {
    name: 'PartnersConsulence',
    components: {
        TheHeader,
        MobileHeader,
        TheFooter,
        AuthenticationPopup
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ContactsEditPanel',
    data() {
        return {
            showErrors: false,
            address: {
                postalCode: null,
                provincie: null,
                street: null,
                city: null
            },
            contacts: {
                email: null,
                phone: null
            },
            socials: {
                instagram: null,
                linkedin: null,
                facebook: null,
                twitter: null
            },
            website: null
        }
    },
    created() {
        this.$parent.save = this.save;
        
        try { this.address.postalCode = this.$store.state.partner.posizione.codice_postale; } catch (e) { this.address.postalCode = null; }
        try { this.address.provincie = this.$store.state.partner.posizione.provincia; } catch (e) { this.address.provincie = null; }
        try { this.address.street = this.$store.state.partner.posizione.indirizzo; } catch (e) { this.address.street = null; }
        try { this.address.city = this.$store.state.partner.posizione.citta; } catch (e) { this.address.city = null; }
        try { this.contacts.email = this.$store.state.partner.contatti.email; } catch (e) { this.contacts.email = null; }
        try { this.contacts.phone = this.$store.state.partner.contatti.telefono; } catch (e) { this.contacts.phone = null; }
        try { this.socials.instagram = this.$store.state.partner.contatti.social.instagram; } catch (e) { this.socials.instagram = null; }
        try { this.socials.linkedin = this.$store.state.partner.contatti.social.linkedin; } catch (e) { this.socials.linkedin = null; }
        try { this.socials.facebook = this.$store.state.partner.contatti.social.facebook; } catch (e) { this.socials.facebook = null; }
        try { this.socials.twitter = this.$store.state.partner.contatti.social.twitter; } catch (e) { this.socials.twitter = null; }
        try { this.website = this.$store.state.partner.sito; } catch (e) { this.website = null; }
    },
    methods: {

        async save() {
            if (!this.validateInput()) { return; }
            
            // Save on vuex
            this.$store.commit('partner/set', {
                contatti: {
                    email: this.contacts.email,
                    telefono: this.contacts.phone,
                    social: {
                        facebook: this.socials.facebook,
                        instagram: this.socials.instagram,
                        linkedin: this.socials.linkedin,
                        twitter: this.socials.twitter,
                    }
                },
                posizione: {
                    citta: this.address.city,
                    provincia: this.address.provincie,
                    indirizzo: this.address.street,
                    codice_postale: this.address.postalCode
                },
                sito: this.website,
            });
            // Dispatch vuex save
            await this.$store.dispatch('partner/saveContacts');
            this.$store.commit('panelEditPopup/close');
        },

        validateInput() { //Todo
            if (!this.address.postalCode || this.address.postalCode === '' ||
                !this.address.provincie || this.address.provincie === '' ||
                !this.address.street || this.address.street === '' ||
                !this.address.city || this.address.city === '' ||
                !this.contacts.email || this.contacts.email === '' ||
                !this.contacts.phone || this.contacts.phone === ''
            ) {
                this.showErrors = true;
                return false;
            }
            return true;
        }

    }
}

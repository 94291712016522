// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/home/popup-bg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrp[data-v-907a368c]{background:rgba(0,0,0,.5882352941176471)}#authentication-popup[data-v-907a368c]{top:50%;left:50%;transform:translate(-50%,-50%)}.bg-rtd[data-v-907a368c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;top:0;left:0;width:100%;height:100%;background-size:cover;background-position:bottom}.bg-gradient[data-v-907a368c]{background:linear-gradient(179.4deg,rgba(247,79,120,.05) -2.62%,rgba(247,89,79,.16) 51.34%,rgba(106,155,244,.71) 126.87%)}.popup-enter-active[data-v-907a368c],.popup-leave-active[data-v-907a368c]{transition:opacity .5s}.popup-enter[data-v-907a368c],.popup-leave-active[data-v-907a368c]{opacity:0}.fade-in-out-enter-active[data-v-907a368c]{transition:opacity .5s}.fade-in-out-enter[data-v-907a368c],.fade-in-out-leave-active[data-v-907a368c]{opacity:0}.lds-ripple[data-v-907a368c]{display:inline-block;position:relative;width:80px;height:80px}.lds-ripple div[data-v-907a368c]{position:absolute;border:4px solid var(--colorPurple);opacity:1;border-radius:50%;-webkit-animation:lds-ripple-data-v-907a368c 1s cubic-bezier(0,.2,.8,1) infinite;animation:lds-ripple-data-v-907a368c 1s cubic-bezier(0,.2,.8,1) infinite}.lds-ripple div[data-v-907a368c]:nth-child(2){-webkit-animation-delay:-.5s;animation-delay:-.5s}@-webkit-keyframes lds-ripple-data-v-907a368c{0%{top:36px;left:36px;width:0;height:0;opacity:0}4.9%{top:36px;left:36px;width:0;height:0;opacity:0}5%{top:36px;left:36px;width:0;height:0;opacity:1}to{top:0;left:0;width:72px;height:72px;opacity:0}}@keyframes lds-ripple-data-v-907a368c{0%{top:36px;left:36px;width:0;height:0;opacity:0}4.9%{top:36px;left:36px;width:0;height:0;opacity:0}5%{top:36px;left:36px;width:0;height:0;opacity:1}to{top:0;left:0;width:72px;height:72px;opacity:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SupportWidget',
    data() {
        return {
            isWidgetOpen: false,
            form: {
                email: null,
                message: null
            },
            success: null
        }
    },
    methods: {
        toggleWidget() {
            this.success = null;
            this.isWidgetOpen = !this.isWidgetOpen;
        },
        async submit(e) {
            e.preventDefault();

            const payload = {
                email: this.form.email,
                message: this.form.message,
                partner_email: this.$localStore.get('pEmail'),
                user_email: this.$localStore.get('email')
            };

            const {data} = await this.$axios.post(`${this.$config.partners.publicApiUrl}/emails/support_request`, payload);

            this.success = true;
        }
    }
}

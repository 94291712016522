//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PlannerSidebarStep",
  props: {
    step: {
      type: Number,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import TheButton from '../../../components/general/TheButton.vue'
	import { mapMutations } from 'vuex'
	import User from '~/assets/js/User'
	import storage from '~/assets/js/Storage'

    export default {
        name: 'PartnerAuthenticationHeader',
        data() {
            return {
                scrolled: false
            }
        },
        props: {
            homepage: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            username() {
                return this.$localStore.get('pEmail');
            }
		},
        components: {
            TheButton
        },
        beforeMount () {
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy () {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

import { a } from "aws-amplify";
import extend from "~/utilities/extend-vue-app";

export default async function ({ app }) {
  extend(app, {
    mounted() {
      window.dataLayer = window.dataLayer || [];
      window.gtag = () => {
        dataLayer.push(arguments);
      };
      gtag("js", new Date());
      gtag("config", "AW-11473768121");
      console.log("gtag setup", gtag);

      if (
        !app.$cookies.get("authenticated") &&
        app.$localStore.get("authenticated")
      ) {
        console.log(
          'No "authenticated" cookie found but "authenticated" found in local storage. Manually logging out.'
        );
        this.$localStore.clearAll();
        window.location.href = "/";
      }
    },
  });
}
